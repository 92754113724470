// This drawing represents the stops
import React, {useEffect, useState} from "react"
import PropTypes from 'prop-types';

import "./StopsDrawing.css"

const StopsDrawing = (props) => {
    const {numberStops, dotPosition, CustomDot} = props;

    const [height, setHeight] = useState(2); //height in pixels.
    const [width, setWidth] = useState(2); //height in pixels.

    const tickWidth = 15;
    const yOffset = 16; //number of pixels before schedule line starts.
    const tickOffset = -4;

    const locationDot = function (yPos) {
        return (
            <svg id="location-dot" x={width / 2 - 20} y={yPos - 20} width="40" height="40" viewBox="0 0 40 40"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
                {CustomDot && <CustomDot/>}
                {!CustomDot &&
                    <circle
                        className="green"
                        cx="20"
                        cy="20"
                        r="12"
                        fillOpacity="1.0"
                        stroke="#003F56"
                        strokeWidth="3"
                    />
                }
            </svg>
        )
    };

    const getTick = function (yTick, key) {
        return (
            <line
                key={key}
                x1={width / 2}
                y1={yTick + tickOffset}
                x2={width / 2 + tickWidth}
                y2={yTick + tickOffset}
                // stroke="#003F56"
                strokeWidth="6"/>
        );
    };

    const getTerminal = function (yTick, key) {
        return (
            <svg key={key} x={width / 2 - 15} y={yTick - 15} width="30" height="30">
                <rect width="30" height="30"stroke="none"/>
                <rect id="center-terminal" x="10" y="10" width="10" height="10" stroke="none"/>
            </svg>
        )
    };

    function createMarkers() {
        let markers = [];
        for (let i = 0; i < numberStops; i++) {
            let yTick = (i * height / numberStops) + yOffset;
            let marker;
            if (i === 0 || i === numberStops - 1) {
                marker = getTerminal(yTick, "term" + i);
            } else {
                marker = getTick(yTick, "stop" + i);
            }

            markers.push(marker)
        }
        return markers
    }

    // width and height of drawing in pixels.
    useEffect(() => {
        let height = document.getElementById('stops-drawing-div').clientHeight;
        setHeight(height);
        let width = document.getElementById('stops-drawing-div').clientWidth;
        setWidth(width);

    }, [numberStops]);

    return (
        <div className="StopsDrawing" id="stops-drawing-div">
            <svg className="stops-drawing"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox={`0 0 ${width} ${height}`}
                 preserveAspectRatio="xMidYMid">
                {/* vertical line */}
                <line
                    x1={width / 2}
                    y1={yOffset}
                    x2={width / 2}
                    y2={height * (1 - 1 / numberStops) + yOffset}
                    strokeWidth="6"/>
                {createMarkers()}
                {locationDot(dotPosition * (height - (height / numberStops)) + yOffset)}
            </svg>
        </div>
    );
}

StopsDrawing.propTypes = {
    numberStops: PropTypes.number.isRequired,
    dotPosition: PropTypes.number.isRequired,
};

export default StopsDrawing;
