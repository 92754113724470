import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import './AvantiKey.css';
import AvantiKeyIcon from '../AvantiKeyIcon';


function AvantiKey (props){

  const {layout, greenIcon, amberIcon, redIcon, otherIcon} = props
   
    return (
      <div className="AvantiKey">
        <div className='key-container'>
          <h2 className> Map key </h2>

          <div className='padding-small'></div>
           <h3 className='padding-medium'>Trains operated by Avanti</h3> 
    
          <div className='row'>
            <div className="train-icon-div" >
              <AvantiKeyIcon color={"green"}/>
            </div>
            <div className='train-icon-description'> <p> On time </p> </div>
          </div>
          <div className='row'>
            <div className="train-icon-div" >
            <AvantiKeyIcon color={"amber"}/>
            </div>
            <div className='train-icon-description'> <p> 1-10 mins delay </p> </div>
          </div>
          <div className='row'>
            <div className="train-icon-div" >
            <AvantiKeyIcon color={"red"}/>
            </div>
            <div className='train-icon-description'> <p> 10+ mins delay </p> </div>
          </div>

          <h3 className='padding-medium'>Trains not operated by Avanti</h3> 
    
              <div className='row'>
                <div className="train-icon-div" >
                  <AvantiKeyIcon color={"green"} other={true}/>
                </div>
                <div className='train-icon-description'> <p> On time </p> </div>
              </div>
              <div className='row'>
                <div className="train-icon-div" >
                <AvantiKeyIcon color={"amber"} other={true}/>
                </div>
                <div className='train-icon-description'> <p> 1-10 mins delay </p> </div>
              </div>
              <div className='row'>
                <div className="train-icon-div" >
                <AvantiKeyIcon color={"red"} other={true}/>
                </div>
                <div className='train-icon-description' > <p> 10+ mins delay </p> </div>
              </div>

          <div className='direction-description'>
            <p> For a moving train, the arrow indicates the direction of travel.
               When a train is stationary the marker arrow 
              points north. </p> 
          </div>
        </div>
           
      </div>
  )
   
}

const iconSize = 30;

AvantiKey.propTypes = {
  layout: PropTypes.string,
}


AvantiKey.defaultProps = {
  greenIcon: <AvantiKeyIcon className="train-icon" color={"green"} heading={45} size={iconSize} tocCode='GR'/>,
  amberIcon: <AvantiKeyIcon className="train-icon" color={"amber"} heading={45} size={iconSize} tocCode='GR'/>,
  redIcon: <AvantiKeyIcon className="train-icon" color={"red"} heading={45} size={iconSize} tocCode='GR'/>,
  otherIcon:  <AvantiKeyIcon className="train-icon" delay={15} heading={45} size={iconSize} />
};


export default memo(AvantiKey)
