import React, {useState} from 'react';

import logo from "./logo.png";


import './LnerLogo.css';

function LnerLogo(props) {

    return (
            <div className="LnerLogo">
                    <img src={logo} alt={"LNER Logo"}/>
            </div>
           
    );
}

export default LnerLogo;
