// This component shows the train (it includes a summary and ETA sharing capability)


import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import './Train.css';
import { useQuery } from 'react-query'
import axios from 'axios'
import ReactSelect, { createFilter } from 'react-select'
import classnames from 'classnames';

import Stops from 'components/Train/Stops';
import TrainSummary from 'components/TrainSummary'
import BackButton from 'components/BackButton'
import useStationSelector from './useStationSelector'
import EtaSharing from '../EtaSharing'
import { useTranslation } from 'react-i18next'
import { apiBaseUrl } from "../../utils/utils";


function Train({
  currentTrain,
  onBackClick,
  className,
  showEta,
  CustomDot,
  setTracking,
  tracking,
}) {

  const [page, setPage] = useState(0);
  const [selectedStation, setSelectedStation] = useState()

  const { optionsReducer: stationOptionsReducer } = useStationSelector()


  const { t, i18n } = useTranslation();

  // Welsh language hack
  let lang_arg = '';
  if (i18n.language == 'cy') {
    lang_arg = '?lang=cy'
  }

  // This is for getting information on the current train (once current train has been selected)
  // Todo protentially move this to child "Train" component

  const base_url = apiBaseUrl();
  const { isLoading, isError: isTrainError, data, error } = useQuery(['train', currentTrain, lang_arg], () => { return axios.get(`${base_url}/api/train-information/${currentTrain}${lang_arg}`) },
    {
      enabled: currentTrain !== undefined,
      refetchInterval: 20000
    })


  const nextPage = function () {
    setPage(currentPage => currentPage + 1)
  }

  const previousPage = function () {
    setPage(currentPage => currentPage - 1)
  }

  const onStationChange = function (selected) {
    if (selected == null) {
      setSelectedStation(undefined)
    } else {
      setSelectedStation(selected)
    }
  }

  if (isLoading) {
    return (
      <div className='Train'>
        <h2 className='center'> Loading...</h2>
      </div>
    )
  }

  if (showEta == false) {
    return (
      <div className="Train">
        <div className='container padding-top'>
          {onBackClick !== undefined &&
            (
              <div className='back-button-div'>
                <BackButton label={t('train.results')} onClick={onBackClick} />
              </div>
            )
          }
          <TrainSummary
            train={data?.data}
            setTracking={setTracking}
            tracking={tracking}
          />
          <Stops loading={false} stops={data?.data.stops} CustomDot={CustomDot} />
        </div>
      </div>
    )
  }

  //Your train journey
  if (page === 0) {
    return (
        <div className='body'>
          <div className='container'>
            {onBackClick !== undefined &&
              (
                <div className='back-button-div'>
                  <BackButton label={t('train.results')} onClick={onBackClick} />
                </div>
              )
            }
            <TrainSummary
              train={data?.data}
              setTracking={setTracking}
              tracking={tracking}
              onShareClick={nextPage}
            />
            <div className='padding-small'></div>
            <Stops loading={false} stops={data?.data.stops} CustomDot={CustomDot} />
          </div>
        </div>
    )
  }

  // Select your destination page
  if (page === 1) {
    return (
      <div className="Train">
        <div className='container'>
          <div className='back-button-div'>
            <BackButton label={t('train.train_journey')} onClick={previousPage} />
          </div>
          <h1 className="padding-large"> {t('train.select_your_destination')} </h1>
          <TrainSummary train={data?.data} recentreEnabled={false} />

          <div className="form-container">
            <div className='label'> {t('train.destination')}</div>
            <ReactSelect
              className={classnames('autocomplete', className)}
              classNamePrefix="autocomplete"
              options={stationOptionsReducer(data?.data.stops)}
              value={selectedStation}
              onChange={onStationChange}
              placeholder={t('train.enter_your_station')}
              isClearable={true}
              autoFocus={true}>
            </ReactSelect>
          </div>
          <button className="button-dark" onClick={nextPage}> {t('train.confirm_station')} </button>
          <div className='padding-large'></div>
        </div>
      </div>
    )
  }

  // Share your arrival page
  if (page === 2) {
    return (
      <div className="Train">
        <div className='container padding-top'>
          <div className='back-button-div'>
            <BackButton label={t('train.select_station')} onClick={previousPage} />
          </div>
          <h1 className="padding-large"> {t('train.share_my_journey')}      </h1>
          <TrainSummary train={data?.data} recentreEnabled={false} />
          <div className='padding-small'>
            <EtaSharing train={data?.data} crs={selectedStation?.value} />
          </div>
          <div className='padding-large'></div>
        </div>
      </div>
    )
  }
}

Train.propTypes = {
  currentTrain: PropTypes.any,
}

export default memo(Train)
