// Converts an svg component to image. 
import { renderToString } from 'react-dom/server';
import { useEffect } from 'react';


export function useComponentToImage(component, name, size = 70) {

  let img = new Image(size, size);

  useEffect(() => {

    // Convert component to string. 
    const componentStr = renderToString(component, size)
    // Component string to image. 
    let blob = new Blob([componentStr], { type: 'image/svg+xml' });
    let url = URL.createObjectURL(blob);
    img.src = url;

  }, [component, name, size])

  return img
}


export default useComponentToImage