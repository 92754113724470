// Adds an svg to the map.

import {useEffect, useLayoutEffect} from 'react';
import { useMap } from "react-map-gl";


export function useMapImage(img, name) {

    const { current: map } = useMap();
      
    useEffect(() => {
    
    if (map && img!==undefined) {
        map.on('styleimagemissing',()=>{
            if(!map.hasImage(name)) map.addImage(name, img, { sdf: false }) 
        }
        )
    }
  }, [map, img]);
}