import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';


function TfwTrainIcon(props) {
    const {
        size, 
        heading, 
        color,
        className} = props;


    return (
            <svg className={classnames('TfwTrainIcon', className)}
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
                viewBox="0 0 40 40"
            >
                <circle
                    cx="20"
                    cy="20"
                    r="16"
                    fillOpacity="1"
                    fill="#ffff"
                    strokeWidth="6"
                    stroke={color}
                />
                <g fill="#333333" transform={`translate(4.5, 4.5) rotate(${heading}, 15.5, 15.5)`}>
                    <path fill="#333333"
                          d="M 8.9101796,21.718563 15.732036,5.6152695 22.600299,21.76497 15.732036,18.284431 Z"
                          id="path845"/>
                </g>
            </svg>
    );
}

TfwTrainIcon.propTypes = {
    delay: PropTypes.number,
    isFocused: PropTypes.bool,
    size: PropTypes.number,
    heading: PropTypes.number,
    tocCode: PropTypes.string,
    coloring: PropTypes.string
};

TfwTrainIcon.defaultProps = {
    isFocused: false,
    size: 64,
    heading: 0,
    tocCode: undefined,
    coloring: undefined
};

export default React.memo(TfwTrainIcon);
