
import {useState, useEffect, useLayoutEffect} from 'react';
import turf from "turf";
import WebMercatorViewport from "viewport-mercator-project";

function useTrackTrains(locos, setViewport, buffer=18, ){

    const [tracking, setTracking] = useState(false)
    const [trackRids, setTrackRids] = useState()
    const [trackViewport, setTrackViewport] = useState()
    const [trackBounds, setTrackBounds] = useState()

    //from an array of turf points computes a bounding box. 
    const findBbox = function(points, buffer){

        if(points.length == 1){
            let q = turf.buffer(points[0], buffer);
            return turf.bbox(q);
        }
        let features = turf.featureCollection(points)
        let d = turf.convex(features)
        let q = turf.buffer(d, buffer);
        return turf.bbox(q);
    }
    
    useEffect(()=>{

        if(!tracking){
            return
        }
        
        try{
            if(locos == undefined || trackRids == undefined){
                return 
            }
    
            // filter locos to those we are tracking
            let trackLocos = locos.filter((loco) => trackRids.includes(loco.rid));
            if(trackLocos=== undefined || trackLocos.length==0){
                return
            }
            //compute viewport
            let points = trackLocos.map((loco)=>{
                return turf.point([loco.location.lon, loco.location.lat])
                })

            let bbox = findBbox(points, buffer)
            setTrackBounds(bbox)
          
        }catch{
            console.log('Error in tracking code')
        }
        return

    }, [trackRids, tracking, locos])

    // TODO probably add an error state 
    let trackError = false;

    useLayoutEffect(()=> {
        // fitBounds takes extents not bounds and can add padding. 
        //https://stackoverflow.com/questions/54225512/update-viewport-state-after-fitbounds-is-called
       
        if (trackBounds !== undefined ){
            const a = new WebMercatorViewport({width: window.innerWidth, height: window.innerHeight})
            const {latitude, longitude, zoom} = a.fitBounds([[trackBounds[0], trackBounds[1]], [trackBounds[2], trackBounds[3]]], 
                {padding: {top: 42, bottom: 40, left: 40, right: 40}});
            setViewport(viewport => ({
            ...viewport,
            longitude,
            latitude,
            zoom, 
            transitionDuration: 10,
         }));
        }
        return
    }, [trackBounds])

    return {trackViewport, setTrackRids, trackError, trackBounds, tracking, setTracking}
}

export default useTrackTrains