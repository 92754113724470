import AvantiStopsDot from "themes/avanti/components/AvantiStopsDot";
import AvantiTrainIcon from "themes/avanti/components/AvantiTrainIcon";
import AvantiKeyIcon from "themes/avanti/components/AvantiKeyIcon";
import AvantiLogo from 'themes/avanti/components/AvantiLogo'
import AvantiKey from 'themes/avanti/components/AvantiKey'


const AvantiTheme = {
        name: 'avanti',
        toc: 'VT',
        StopsDot: AvantiStopsDot,
        TrainIcon: AvantiTrainIcon,
        KeyIcon: AvantiKeyIcon,
        Logo: AvantiLogo,
        Key: AvantiKey,
        HomeWidget: undefined,

        viewportDefaults: {
            latitude: 51.541729,
            longitude: -0.152247,
            zoom: 9,
        },

        routeLineColor: '#65E2C0',
        routeBorderColor: '#005059',

        mapboxStyle: "mapbox://styles/signalbox/cliajjje100w901pg7plf40az",

        navigationControlStyle: { right: 12, top: 12 },
        geolocateControlStyle: { right: 12, top: 140 },

        cssVariables: {
            '--color-primary': '#141E2A',
            '--color-secondary': '#FFFFFF',

            '--color-text-secondary': 'var(--color-text-primary',
            '--color-text-error': 'var(--color-brand-secondary)',

            '--color-border': '#606060',

            '--color-rag-red': '#742420',
            '--color-rag-amber': '#dc4713',
            '--color-rag-green': '#2d4f59',

            '--color-stops-line': '#ff4606',
            '--color-stops-end-dot': "#ff4606",

            '--font-family-primary': 'Averta-Semibold',
            '--font-family-secondary': 'Averta',

            // '--font-button': '16px var(--font-family-secondary) bold',

            '--overlay-box-shadow': 'none',
            '--overlay-border': 'var(--color-border) 1px solid',
            '--border-internal': 'var(--color-border) 1px solid'
        }
}

export default AvantiTheme;