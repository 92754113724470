import React, {useEffect, useState} from "react";
import { useQuery } from 'react-query'
import axios from 'axios'
import {apiBaseUrl} from "../../utils/utils";


function useTrainOperators() {


    const base_url = apiBaseUrl();

    const { isLoading,
        isError,
        data: operators,
        error: operatorsError } = useQuery(['train_operators'], () => { return axios.get(`${base_url}/api/train-operators`) })

    // TODO needs to be in call back
    // maybe in state variable?

    const findOperator = function(operators, toc){

        const operator = operators?.data.train_operators.find(train_operator => train_operator.atoc_code == toc)
        return operator

    }

    return {
        isLoading,
        isError,
        operators,
        findOperator
    };
}

export default useTrainOperators;
