import { useCallback } from 'react'

export default function useTrainOperatorSelector () {
  
  
  const optionsReducer = useCallback(data => {

    const b = data?.train_operators?.reduce((trainOperators, { atoc_code, company_name }) => ([
      ...trainOperators,
      {
        label: company_name,
        value: atoc_code
      }
    ]
    ), [])

     //sort in alphabetical order.
     const d = b.sort(function(a, b) {
      const nameA = a.label.toUpperCase(); // ignore upper and lowercase
      const nameB = b.label.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    return d

  }, [])

  
  return {
    optionsReducer
  }
}
