
import LnerLogo from 'themes/lner/components/LnerLogo'
import LnerStopsDot from "themes/lner/components/LnerStopsDot";
import LnerTrainIcon from 'themes/lner/components/LnerTrainIcon'
import LnerKey from 'themes/lner/components/LnerKey'


const LnerTheme = {
 
    toc: 'GR',
    Logo: LnerLogo,
    StopsDot: LnerStopsDot,
    TrainIcon: LnerTrainIcon,
    Key: LnerKey,
    HomeWidget: undefined,

    viewportDefaults: {
        latitude: 53.957561,
        longitude: -1.093810,
        zoom: 9,
    },

    mapboxStyle: "mapbox://styles/signalbox/cla9iq4bq000314phim02fvvv",
    navigationControlStyle: { right: 12, top: 12 },
    geolocateControlStyle: { right: 12, top: 132 },

    routeLineColor: '#E17F73',
    routeBorderColor: '#181818',

    cssVariables: {
        '--color-primary': '#CE132E',
        '--color-primary-hover': '#A80D25',
        '--color-primary-lighter': '#d75e70',
        '--color-secondary': 'white',

        '--color-hyperlink': '#440F11',
        '--color-ripple': ' rgba(206, 20, 46, 0.8)',

        '--color-border': '#606060',

        '--color-rag-red': '#BC253A',
        '--color-rag-amber': '#E0662A',
        '--color-rag-green': '#258825',

        '--color-stops-line': '#440F11',
        '--color-stops-end-dot': "#CE142E",

        /* Menu colors */
        '--color-menu-buttons': '#440F11', /*LNER Oxblood */
        '--color-menu-buttons-select': '#CE142E', /* LNER  prime red */
        '--color-menu-buttons-select-background': '#FAE7EA',

        '--color-text-rag-red': 'var(--color-rag-red)',
        '--color-text-rag-amber': 'var(--color-rag-amber)',
        '--color-text-rag-green': 'var(--color-rag-green)',

        '--color-text-heading': '#000000',
        '--color-text-subheading': '#000000',
        '--color-text-paragraph': '#000000',
        '--text-transform-heading': 'uppercase',

        '--font-family-primary': "Raleway, sans-serif",
        '--font-family-secondary': "Raleway, sans-serif",


        /* Overlays */
        '--overlay-border': '1px solid #DADADA',
        '--overlay-border-radius': '10px',
        '--overlay-box-shadow': '0px 4px 8px rgba(0, 0, 0, 0.12), 0px 16px 32px rgba(0, 0, 0, 0.08)',

        '--border-internal': '1px solid #E5E5E5'
    }
}

export default LnerTheme;



