// Finds trains that conform to the given search parameters. 
import lineString from 'turf-linestring';
import booleanIntersects from '@turf/boolean-intersects';
import { bboxPolygon, bbox } from 'turf';
import {useState, useEffect} from 'react';

function useFilterLocos(locos, trackedRid, filterRids, boundingBox){
    
    const [tempLocos, setTempLocos] = useState(locos)
    // These are all the train locations that conform to the given search parameters. 
    const [filteredLocos, setFilteredLocos] = useState(tempLocos)

    // these 
    useEffect(()=>{

        let a = locos.filter((train) => {
            if (train.rid === trackedRid){
                return true;
            }
            if (filterRids === undefined){
                return true
            }
            if (filterRids.includes(train.rid)){
                return true
            }
            return false
        });

        setTempLocos(a)

    }, [trackedRid, locos, filterRids])
    

    useEffect(()=>{

        let a = tempLocos.filter((train) => {
            if (boundingBox === undefined) {
                return true;
            }
            if (train.rid === trackedRid){
                return true;
            }

            var linestring1 = lineString([ [train.location.lon, train.location.lat], [train.predicted_location.lon, train.predicted_location.lat]]);
            var bb = bboxPolygon(bbox(lineString(boundingBox.toArray())));
            var intersects = booleanIntersects(bb, linestring1)
            
            return intersects
        });

        setFilteredLocos(a)
        
    }, [tempLocos, trackedRid, boundingBox])

    return filteredLocos
}

export default useFilterLocos