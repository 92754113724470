import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './TrainlineIcon.css';


const getIconColor = function (delay) {
    if (delay === null || delay === undefined) {
        return "green"
    }

    if (delay > 0 && delay < 10) {
        return "amber"
    }

    if (delay >= 10) {
        return "red"
    }
    return "green"
}


function TrainlineIcon(props) {
    const { delay,
        isFocused,
        size,
        heading,
        tocCode,
        coloring,
        className } = props;


    let iconStyle = {
        height: size,
        width: size,
    };


    iconStyle.transform = 'scale(1.2, 1.2)';
    if (isFocused) {
        iconStyle.transform = 'scale(1.5, 1.5)';
    }
 
    return (
            <svg className={classnames('TrainlineIcon', className)} style={iconStyle}
                height={size}  viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_8_1730)">
                    <circle className={`${getIconColor(delay)}`} cx="23" cy="23" r="12" fill="#00A88F" />
                    <circle cx="23" cy="23" r="13.5" stroke="white" strokeWidth="3" />
                </g>
                <path transform={`rotate(${heading - 90},23, 23)`}d="M21.7145 29.2105L26.2059 23.8732C26.5221 23.4975 26.5186 22.9478 26.1978 22.576L21.4624 17.0894" stroke="white" strokeWidth="3" strokeLinecap="round" />
                <defs>
                    <filter id="filter0_d_8_1730" x="0.831917" y="0.831917" width="44.3362" height="44.3362" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feMorphology radius="1.43362" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_8_1730" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="2.86723" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8_1730" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8_1730" result="shape" />
                    </filter>
                </defs>
            </svg>
    );
}

TrainlineIcon.propTypes = {
    isFocused: PropTypes.bool,
    size: PropTypes.number,
    heading: PropTypes.number,
    color: PropTypes.string
};

TrainlineIcon.defaultProps = {
    isFocused: false,
    size: 64,
    heading: 0,
    color: 'green'
};

export default React.memo(TrainlineIcon);
