import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './TfwTrainIcon.css';


const getIconColor = function (delay) {
    if (delay === null || delay === undefined) {
        return "green"
    }

    if (delay > 0 && delay < 10) {
        return "amber"
    }

    if (delay >= 10) {
        return "red"
    }
    return "green"
}

const getTocStyle = function(tocCode){
    
    const a = {"AW": "grey", "CC": "#380040", "CH": "#770088", "CS": "#7f0090", "EM": "#83009a", "ES": "#4300a2", "GC": "#0000ad", "GN": "#0000c5", "GR": "#0009dd", "GW": "#004bdd", "GX": "#007add", "HC": "#008ddd", "HT": "#009bd7", "HX": "#00a4bb", "IL": "#00aaa5", "LE": "#00aa93", "LM": "#00a668", "LO": "#009e28", "LT": "#00a200", "ME": "#00b200", "NT": "#00c400", "NY": "#00d400", "PC": "#00e700", "RT": "#00f700", "SE": "#3bff00", "SJ": "#a1ff00", "SN": "#ccf900", "SR": "#e8f000", "SW": "#f4e200", "TL": "#fdcf00", "TP": "#ffb900", "TW": "#ff9d00", "VT": "#ff5100", "WR": "#ff0900", "XC": "#ee0000", "XR": "#6927A5", "ZB": "#d40000", "ZF": "#cc0000", "ZM": "#cc6c6c", "ZZ": "#cccccc"}
    var color = a.hasOwnProperty(tocCode) ? a[tocCode] : 'grey'
    return {fill:   color, fillOpacity: 0.6}
}

function TfwTrainIcon(props) {
    const {delay, 
        isFocused, 
        size, 
        heading, 
        tocCode, 
        coloring,
        className} = props;

    let iconStyle = {
        height: size,
        width: size,
    };

    if (tocCode !== "AW"){
        iconStyle.transform = 'scale(0.7, 0.7)';
    }
    if (isFocused) {
        iconStyle.transform = 'scale(1.5, 1.5)';
    }

    return (
        <div className={classnames('TfwTrainIcon', className, {'not-tfw': tocCode !== "AW"})} 
             style={iconStyle}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
                viewBox="0 0 40 40"
            >
                <circle
                    className={`${getIconColor(delay)}`}
                    style={coloring==="toc" ? getTocStyle(tocCode):null}
                    cx="20"
                    cy="20"
                    r="16"
                    fillOpacity="1"
                    fill="#ffff"
                    strokeWidth="6"
                />
                <g fill="#333333" transform={`translate(4.5, 4.5) rotate(${heading}, 15.5, 15.5)`}>
                    <path fill="#333333"
                          d="M 8.9101796,21.718563 15.732036,5.6152695 22.600299,21.76497 15.732036,18.284431 Z"
                          id="path845"/>
                </g>
            </svg>
        </div>
    );
}

TfwTrainIcon.propTypes = {
    delay: PropTypes.number,
    isFocused: PropTypes.bool,
    size: PropTypes.number,
    heading: PropTypes.number,
    tocCode: PropTypes.string,
    coloring: PropTypes.string
};

TfwTrainIcon.defaultProps = {
    isFocused: false,
    size: 64,
    heading: 0,
    tocCode: undefined,
    coloring: undefined
};

export default React.memo(TfwTrainIcon);
