// This fetches train information consisting of train information.

import {useEffect, useState} from 'react';
import axios from 'axios'
import { useQuery } from 'react-query'
import {apiBaseUrl} from "../utils/utils";


function useTrainInfo(setCurrentTrain){
    //Change name to TrainIdParser, or something similar


    const base_url = apiBaseUrl();
    const [trainId, setTrainId] = useState(undefined)
    const  [trainInfo, setTrainInfo] = useState(undefined)

    //Fetching train information
    const { isLoading, isError, data, error } = useQuery(['trainInfo', trainId], () => { return axios.get(`${base_url}/api/train-information/${trainId}`) },
    {
        enabled: trainId !== undefined && trainId.length === 6
    })

    useEffect(()=>{
        if (data){
            setTrainInfo(data?.data)
            setCurrentTrain(data?.data['rid'])
        }
    }, [data])

    return {isLoading, isError, trainInfo, setTrainId}

}

export default useTrainInfo
