
import React, { memo, useState, useCallback, useEffect } from 'react';
import { Layer, Source } from "react-map-gl";
import { useQuery } from 'react-query'
import axios from 'axios'
import './Route.css';
import {apiBaseUrl} from "../../utils/utils";


const getDelayColor = function (delay) {

  if (delay === null || delay === undefined) {
    return getComputedStyle(document.body).getPropertyValue('--color-rag-green')
  }
  if (delay > 0 && delay < 10) {
    return getComputedStyle(document.body).getPropertyValue('--color-rag-amber')
  }
  if (delay >= 10) {
    return getComputedStyle(document.body).getPropertyValue('--color-rag-red')
  }
  return getComputedStyle(document.body).getPropertyValue('--color-rag-green')
}



function Route(props) {

  const {
    currentTrain,
    delay = 0,
    toc,
    themeToc,
    routeBorderColor = '#000000'
  } = props;

  const base_url = apiBaseUrl();
  const { isLoading, isError, data, error } = useQuery(['route', currentTrain], () => { return axios.get(`${base_url}/api/route/${currentTrain}`) },
    {
      enabled: currentTrain !== undefined,
      staleTime: Infinity,
      cacheTime: Infinity,
    })

  const lineWidth = 4;

  let lineColor;
  if (themeToc !== undefined) {
    const neutralColor = getComputedStyle(document.body).getPropertyValue('--color-rag-neutral')
    lineColor = toc != themeToc ? neutralColor : getDelayColor(delay);
  } else {
    lineColor = getDelayColor(delay)
  }


  // fill
  const lineLayerStyle = {
    id: 'route_line',
    type: 'line',
    paint: {
      "line-color": lineColor,
      "line-width": lineWidth,
      "line-opacity": 1,
    },
    layout: {},
    beforeId: 'stations'

  };


  //line 2
  const lineLayerStyle2 = {
    id: 'route_line2',
    type: 'line',
    paint: {
      "line-color": routeBorderColor,
      "line-width": 1,
      "line-opacity": 1,
      "line-gap-width": lineWidth
    },
    layout: {},
    beforeId: 'stations'
  };

  // if loading returns nothing (i.e. stop previous route being shown)
  if (isLoading || data === undefined) {
    return (
      <div className='Route'>
      </div>
    )
  }

  const geojson = data?.data;

  return (
    <div className='Route'>

      <Source id="route_line" type="geojson" data={geojson}>
        <Layer {...lineLayerStyle} />
      </Source>
      <Source id="route_line2" type="geojson" data={geojson}>
        <Layer {...lineLayerStyle2} />
      </Source>
    </div>
  );
}

export default React.memo(Route);
