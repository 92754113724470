import React, { memo, useState, useEffect} from 'react';

const getIconName = function (delay) {
  if (delay < 1 || delay === undefined) {
      return "green"
  }
  if (delay > 0 && delay < 10) {
      return "amber"
  }
  if (delay >= 10) {
      return "red"
  }
}


function useFeatures(iconSize, focusSize, trainLocos, currentTrain){


    const [features, setFeatures] = useState(undefined);

    useEffect(()=>{

      const fs = trainLocos.map(location =>  {
        const scaleFactor = (location.rid === currentTrain)? focusSize: 1
        return {
          type: "Feature",
          id: location.rid,
          geometry: {type: "Point", coordinates: [location.lon, location.lat]},
          properties: {delay: location.delay, rotation: location.heading, rid: location.rid,
          icon: getIconName(location.delay),
          iconSize: iconSize * scaleFactor}
        }
      }
      )       
       setFeatures({ "type": "FeatureCollection","features": fs}) 
       
    }, [iconSize, focusSize, trainLocos, currentTrain])


    return features;
}

export default useFeatures;