import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import './TfwKey.css';
import TfwTrainIcon from '../TfwTrainIcon';
import { useTranslation } from 'react-i18next'


function TfwKey (props){

  const {layout, greenIcon, amberIcon, redIcon, otherIcon} = props

   const { t, i18n } = useTranslation();
   
    return (
      <div className="TfwKey">
        <div className='key-container'>
          <h2 > {t('key.key_title')}</h2>

          <div className='padding-small'></div>
          <h3 className='padding-medium'>{t('key.tfw_trains')}</h3>
    
          <div className='row'>
            <div className="train-icon-div" >
              {greenIcon}
            </div>
            <div className='train-icon-description'> <p> {t('key.green_text')} </p> </div>
          </div>
          <div className='row'>
            <div className="train-icon-div" >
              {amberIcon}
            </div>
            <div className='train-icon-description'> <p> {t('key.amber_text')}  </p> </div>
          </div>
          <div className='row'>
            <div className="train-icon-div" >
              {redIcon}
            </div>
            <div className='train-icon-description'> <p> {t('key.red_text')}  </p> </div>
          </div>

          <h3 className='padding-medium'>{t('key.other_trains')}</h3>

          <div className='row'>
            <div className="train-icon-div" >
              {otherIcon}
            </div>
            <div className='train-icon-description'> <p> {t('key.delay_not_shown')} </p> </div>
          </div>

          <div className='direction-description'>
            <p> {t('key.info_text')}</p> 
          </div>
        </div>
           
      </div>
  )
   
}

const iconSize = 30;

TfwKey.propTypes = {
  layout: PropTypes.string,
}


TfwKey.defaultProps = {
  greenIcon: <TfwTrainIcon className="train-icon" delay={0} heading={45} size={iconSize} tocCode='AW'/>,
  amberIcon: <TfwTrainIcon className="train-icon" delay={5} heading={45} size={iconSize} tocCode='AW'/>,
  redIcon: <TfwTrainIcon className="train-icon" delay={15} heading={45} size={iconSize} tocCode='AW'/>,
  otherIcon:  <TfwTrainIcon className="train-icon" delay={15} heading={45} size={iconSize} />
};


export default memo(TfwKey)
