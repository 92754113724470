import React, { useEffect, useState } from "react"
import './FindMyTrain.css'
import useSignalbox from './useSignalbox'
// import Detect from "./Detect.js"
import RippleLoader from "./RippleLoader/RippleLoader"
import BackButton from "../BackButton"
import NoTrains from "./NoTrains"
import { useQuery } from 'react-query'
import axios from 'axios'

import Trains from '../Trains'
import Train from 'components/Train'
import { useTranslation } from 'react-i18next'
import {apiBaseUrl} from "../../utils/utils";




function FindMyTrain(props) {

  const {
    currentTrain,
    setCurrentTrain,
    setTracking,
    tracking,
    CustomDot,
    customNoTrains,
  } = props

  const [errorMessage, setErrorMessage] = useState(null)

  const { t, i18n } = useTranslation();



  const onError = function(message){
    // alert(message);
    setErrorMessage(message);
  }

  const [trains, setTrains, detecting, isError, startFetch, stopFetch, setError] = useSignalbox(undefined, onError)


  const base_url = apiBaseUrl();
  //This is for getting information on the current train (once current train has been selected)
  // Todo protentially move this to child "Train" component
  const { isLoading, isError: isTrainError, data, error } = useQuery(['train', currentTrain], () => { return axios.get(`${base_url}/api/train-information/${currentTrain}`) },
  {
      enabled: currentTrain !== undefined,
      refetchInterval: 20000
  })

  // this effects stops fetching on unmount
  useEffect(()=>{
    return () => stopFetch()
  }, [])

  const onCurrentTrainClick = function(rid){
    setCurrentTrain(rid)
  }

  const onFindMyTrainClick = function(){
    startFetch()
  }

  // Start again (returns to Find My Train button)
  const startAgain = function(){
    stopFetch()
    setTrains(false)
  }

  const onBackToTrains = function(){
    setCurrentTrain(false)
    stopFetch()
  }


  // console.log(detecting)
  if(isError){
    return (
      <div className="FindMyTrain">
        <div className="container padding-top">
          <div className='header-div'>
            <BackButton label={t('find_my_train.try_again')} onClick={startAgain}/>
          </div>
          <h1 className="text-center padding-large">Error</h1>
            <div className="no-trains-wrapper">
            {customNoTrains == undefined ?  <NoTrains/> : customNoTrains}
            </div>
            <p className="text-center padding-large">{t('find_my_train.no_train_label')}</p>
            <button className="button-dark" onClick={onFindMyTrainClick}> {t('find_my_train.try_again')} </button>
        </div>
      </div>
    )
  }

  if(detecting){
    return(
      <div className="FindMyTrain">
        <div className="container">
          <div className='header-div'>
          <BackButton label={t('find_my_train.back')} onClick={startAgain}/>
          </div >
          <h1 className="padding-large text-center"> {t('find_my_train.find_my_train')} </h1>
          <div className="detect-loader">
            <RippleLoader/>
          </div>
          <div className="padding-small text-center">
            <h2>{t('find_my_train.searching')}</h2>
          </div>
        </div>
        </div>
    )
  }

  // Train page
  if(currentTrain){
    return (
      <div className="FindMyTrain">

          <Train
            currentTrain={currentTrain}
            CustomDot={CustomDot}
            onBackClick={onBackToTrains}
            setTracking={setTracking}
            tracking={tracking}
            showEta={true}
            />
      </div>
    )
  }

  // No trains found page
  if(trains && trains.length == 0){
      return(
        <div className="FindMyTrain">
          <div className="container">
            <div className='header-div'>
            <BackButton label={t('find_my_train.try_again')}onClick={startAgain}/>
            </div>
              <h1 className="text-center padding-large">{t('find_my_train.no_train')}</h1>
            <div className="no-trains-wrapper">
            {customNoTrains == undefined ?  <NoTrains/> : customNoTrains}
            </div>
              <p className="text-center padding-large">{t('find_my_train.no_train_label')}</p>
              <button className="button-dark" onClick={onFindMyTrainClick}> {t('find_my_train.try_again')}</button>

          </div>
        </div>
      )
  }

  // Trains page
  if(trains && trains.length > 0){
    return(
      <div className="FindMyTrain">
        <div className="container">
          <div className='header-div'>
            <BackButton label={"Start again"} onClick={startAgain}/>
          </div >
        <h1 className="padding-large"> {t('find_my_train.trains_found')}</h1>
          <Trains trains={trains}
          onSelectTrain={onCurrentTrainClick}
          setTracking={setTracking}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="FindMyTrain">
       <div className="container">
        <h1>  {t('find_my_train.find_my_train')}  </h1>
        <div className="padding-small"></div>
        <p className="padding-small">
            {t('find_my_train.label')}

            <br/> <br/>

        </p>
        <div className="padding-large">
          <button className="button-dark" onClick={onFindMyTrainClick}>   {t('find_my_train.find_my_train')} </button>
        </div>
        </div>

    </div>

  )
}




export default FindMyTrain
