import React from 'react';
import PropTypes from 'prop-types';
import {Marker} from 'react-map-gl';

import TrainIcon from 'components/TrainIcon/TrainIcon';


function TrainMarker(props) {
    const {
        id,
        size,
        latitude,
        longitude,
        isFocused,
        label,
        delay,
        onTrainMarkerClick,
        heading,
        tocCode,
        trainIcon,
        coloring
    } = props;


    //prevents error with out of range latitude. 
    let min = -90
    let max = 90
    if(latitude <= min || latitude >= max ){
        return null
    }

    return (
        <Marker
            latitude={latitude}
            longitude={longitude}
            onClick={(e) => onTrainMarkerClick(e, id)}
        >
            <div className="TrainMarker">
                {!trainIcon &&
                    <TrainIcon
                        isFocused={isFocused}
                        label={label}
                        delay={delay}
                        size={size}
                        heading={heading}
                        tocCode={tocCode}
                        coloring={coloring}
                    />
                }
                {trainIcon}
            </div>
        </Marker>
    );
}

TrainMarker.propTypes = {
    delay: PropTypes.number,
    label: PropTypes.string,
    isFocused: PropTypes.bool,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    size: PropTypes.number,
    id: PropTypes.string,
    heading: PropTypes.number,
    tocCode: PropTypes.string,
    coloring: PropTypes.string
};

TrainMarker.defaultProps = {
    id: "",
    heading: 0,
    tocCode: undefined,
};

export default React.memo(TrainMarker);
