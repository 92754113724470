import TrainlineLogo from 'themes/trainline/components/TrainlineLogo'
import TrainlineHomeWidget from "themes/trainline/components/TrainlineHomeWidget";
import TrainlineIcon from "themes/trainline/components/TrainlineIcon";
import TrainlineStopsDot from "themes/trainline/components/TrainlineStopsDot";
import TrainlineKey from "themes/trainline/components/TrainlineKey";
import TrainlineLayerIcon from 'themes/trainline/components/TrainlineLayerIcon';
import BetaTag from './components/BetaTag';


const TrainlineTheme = {
    name: 'trainline',
    BetaTag: BetaTag,
    Logo: TrainlineLogo,
    HomeWidget: TrainlineHomeWidget,
    TrainIcon: TrainlineIcon,
    TrainLayerIcon: TrainlineLayerIcon,
    StopsDot: TrainlineStopsDot,
    Key: TrainlineKey,
    mapboxStyle: "mapbox://styles/signalbox/clsknv3ga020n01pehkq3hk0m",
    cssVariables: {
        '--color-primary': '#2332C4',
        '--color-primary-hover': '#170078',
        '--color-primary-lighter': '#00A88F',
        '--color-secondary': 'white',

        '--color-hyperlink': '#003DA6',
        '--color-ripple': 'rgba(35, 50, 196, 0.7)',

        '--color-border': '#606060',

        '--color-rag-red': '#FF6120',
        '--color-rag-amber': '#FFC508',
        '--color-rag-green': '#00A88F',
        '--color-rag-neutral': '#A6A6A6',

        '--color-stops-line': '#5B6466',
        '--color-stops-end-dot': '#5B6466',
        '--color-stops-end-dot-center': '#FFFFFF',

        /* Menu colors */
        '--color-menu-buttons': '#01A88F', 
        '--color-menu-buttons-select': '#00705F', 
        '--color-menu-buttons-select-background': '#05A88F',


        /* Text colors */
        '--color-text-heading': '#000000',
        '--color-text-subheading': '#525D60',
        '--color-text-paragraph': '#333333',

        '--font-family-primary': "tl-circular-book",
        '--font-family-secondary': "tl-circular-bold",

        '--font-button': '19px var(--font-family-secondary), sans-serif',

        /* Overlays */
        '--overlay-border': '1px solid #DADADA',
        '--overlay-border-radius': '6px',
        '--overlay-box-shadow': '0px 4px 8px rgba(0, 0, 0, 0.12), 0px 16px 32px rgba(0, 0, 0, 0.08)',
        '--border-internal': '1px solid #E5E5E5',

        '--map-border': 'none'

    },
    viewportDefaults: {
        latitude: 54.67538,
        longitude: -5,
        zoom: 5,
    }
}

  export default TrainlineTheme;