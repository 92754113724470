import React, {useState} from 'react';

import logo from "./logo.svg";

import './AvantiLogo.css';

function AvantiLogo() {

    return (
            <div className="AvantiLogo">
                    <img src={logo} alt={"Avanti Logo"}/>
            </div>
           
    );
}

export default AvantiLogo;
