
import {useState, useEffect} from 'react';
import { useDebounce } from "use-debounce";


function useIconDiameter(zoom, scaleFactor=0.5){

 

    const calculateIconDiameter = function (zoom) {

        let iconSize = zoom * 0.8/12
        return iconSize
    }

    const [iconDiameter, setIconDiameter] = useState(0);
      
    const [debouncedIconDiameter] = useDebounce(iconDiameter, 50);

    useEffect(()=>{

        const diameter = calculateIconDiameter(zoom)
        setIconDiameter(diameter * scaleFactor)

    }
    ,[zoom])

    return debouncedIconDiameter;
}

export default useIconDiameter;
