
import React from 'react';
import classnames from 'classnames';

import './BackButton.css';


const BackButton = (props) => {

    const { onClick, className, label } = props;

    return (
        <div className={classnames('BackButton', className)} onClick={_ => onClick && onClick()}>

            <div className='row'>
                <div className='icon'>
                    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.2999 7.30001L0.699903 4.70001C0.599903 4.60001 0.524903 4.49168 0.474903 4.37501C0.424903 4.25835 0.399902 4.13335 0.399902 4.00001C0.399902 3.86668 0.424903 3.74168 0.474903 3.62501C0.524903 3.50835 0.599903 3.40001 0.699903 3.30001L3.2999 0.700012C3.61657 0.383345 3.9789 0.312679 4.3869 0.488012C4.79557 0.662679 4.9999 0.975012 4.9999 1.42501V6.57501C4.9999 7.02501 4.79557 7.33735 4.3869 7.51201C3.9789 7.68735 3.61657 7.61668 3.2999 7.30001Z" />
                    </svg>
                </div>
                <div className='back-label'>
                    {label}
                </div>
            </div>
        </div>
    );
};

export default BackButton;


