// this is a custom hook for managing the theme
import React, { useEffect } from "react"
import TrainlineTheme from 'themes/trainline/TrainlineTheme.js'
import AvantiTheme from 'themes/avanti/AvantiTheme.js'
import { Settings } from "luxon";
import TfwTheme from "themes/tfw/TfwTheme.js";
import LnerTheme from "./lner/LnerTheme.js";
import DefaultTheme from "./default/DefaultTheme.js";


function fetchTheme(themeName = null) {

    let theme; 
    // overides style if theme is in subdomain
    //(bit of an intermediate hack before creating a build-based whitelabel solution.
     
        switch (themeName) {
            case 'avanti':
                theme = AvantiTheme
                break;
            case 'lner':
                theme = LnerTheme
                break;
            case 'tfw':
                theme = TfwTheme
                break;
            case 'trainline':
                theme = TrainlineTheme
                break;
            default:
                theme = DefaultTheme // uses the default the
        }
    
        // overides style if theme is in subdomain
        //(bit of an intermediate hack before creating a build-based whitelabel solution.
    
        const france = {
            viewportDefaults: {
                latitude: 47.40088,
                longitude: 1.721455,
                zoom: 5,
            },
            defaultZone: "Europe/Paris",
            mapboxStyle: "mapbox://styles/signalbox/clsxbaa7900ap01po0vo8atwu",
        }
    
        // Country overide
        var host = window.location.hostname
        var subdomains = host.split('.')
        switch(true){
            case subdomains.includes('france') || subdomains.includes('fr'):
                theme = {...theme, ...france};
                break
            default:
        }
    
        //apply css variables
        if (theme !== undefined) {
            for (const key in theme.cssVariables) {
                document.body.style.setProperty(key, theme.cssVariables[key])
            }
        }
    
        // apply default zone if it exists
        if (theme?.defaultZone !== undefined) {
            Settings.defaultZone = theme.defaultZone;
        }
    
    return theme

}


// if theme changes update css variables

export default fetchTheme;