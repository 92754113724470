import React, { useEffect, useState, useMemo, useRef, useContext } from 'react'
import ReactMapGL, { GeolocateControl, NavigationControl, AttributionControl } from "react-map-gl";

import { useParams, useSearchParams } from "react-router-dom"
import { useQuery } from 'react-query'
import axios from 'axios'
import useEtaSharing from 'components/EtaSharing/useEtaSharing';
import fetchTheme from 'themes/fetchTheme';
import SignalboxLogo from 'components/ModalNavigation/SignalboxLogo';
import { ReactComponent as LnerLine } from "./Lner_line.svg";
import { calculateIconSize, apiBaseUrl } from "utils/utils";
import Route from 'components/Route';
import LanguageToggle from 'components/LanguageToggle';
import { useTranslation } from 'react-i18next'
import useFetchLocos from 'hooks/useFetchLocos';
import useFilterLocos from 'hooks/useFilterLocos';
import useDisplayLocos from 'hooks/useDisplayLocos';
import TrainMarkers from 'components/TrainMarkers'
import useTrackTrains from 'hooks/useTrackTrains'
import RecentreControl from 'components/RecentreControl';
import './EtaContainer.scss';
import useMapParams from 'hooks/useMapParams';
import useMapParamsOverride from 'hooks/useMapParamsOveride';
import ThemeContext from 'Contexts.js'



function EtaContainer(props) {

    const { theme, setTheme } = useContext(ThemeContext)

    const mapRef = useRef(null);
    // Extract parameters from url.
    const { rid, crs } = useParams();
    const mapParams = useMapParams()
    useMapParamsOverride(mapParams.setThemeName)

    const { t, i18n } = useTranslation();

    const [iconSize, setIconSize] = useState(32);

    useEffect(() => {
        setTheme(fetchTheme(mapParams.themeName))

    }, [mapParams.themeName])

    const [viewState, setViewState] = useState({
        latitude: 51.515380,
        longitude: -0.282276,
        zoom: 9
    }
    );

    // Welsh language hack
    let lang_arg = '';
    if (i18n.language == 'cy') {
        lang_arg = '?lang=cy'
    }

    const base_url = apiBaseUrl();
    const { isLoading, isError, data, error } = useQuery(['train', rid, lang_arg], () => { return axios.get(`${base_url}/api/train-information/${rid}${lang_arg}`) },
        {
            enabled: rid !== undefined,
            refetchInterval: 20000
        })


    const { onShareClick, getEtaText, getStop, getArrivalTimeString } = useEtaSharing();

    const { isLoading: locosLoading, isError: locosError, locos } = useFetchLocos()
    const filteredLocos = useFilterLocos(locos, rid, rid, undefined) /// if filterRids is defined then trains will be filtered.
    let displayLocos = useDisplayLocos(filteredLocos);


    // track the target rid
    const { tracking,
        setTracking,
        setTrackRids,
        trackError,
        trackBounds
    } = useTrackTrains(locos, setViewState)

    useEffect(() => {
        if (rid !== undefined) {
            setTracking(true)
            setTrackRids(rid);
        }
    }, [rid])

    const viewStateChange = (viewState) => {
        setViewState(viewState);
        setIconSize(calculateIconSize(viewState));
    }

    const onInteraction = () => {
        setTracking(false)
    }


    const currentLoco = useMemo(() => locos.find(loco => loco.rid == rid)
        , [rid, locos])

    const onRecentreClick = function () {
        setTrackRids(rid)
        setTracking(true);
    }
    if (isLoading) {
        return (
            <div className='EtaContainer'>
                <h2 className='center'>{t('loading')}</h2>
            </div>
        )
    }

    // This is for getting the url.
    let stop = getStop(data?.data.stops, crs)
    let train = data?.data;
    const prefix = `/?chrome=hide&train=${rid}`
    const url = theme.name === null ? prefix : prefix + `&style=${theme.name}`

    return (

        <div className="EtaContainer">
            {theme.name === 'tfw' && <LanguageToggle
                setLang={mapParams.setLang}
                lang={mapParams.lang} />}


            {theme.BetaTag && <theme.BetaTag />}
            {theme.name === 'lner' &&
                <div className='lner-line-wrapper'>
                    <LnerLine />
                </div>
            }
            <div className="top-background"></div>
            <div className="bottom-background"></div>
            <div className='grid'> </div>


            <div className='section-text'>
                <div className='logo-wrapper'>
                    {theme.Logo?
                        <theme.Logo /> : <SignalboxLogo />}
                </div>
                <div className="text-wrapper">
                    <h1 className='title'>{t('eta.live_train_journey')}</h1>
                    <h2 className='stations'> {train.origin_name} {t('to')} {train.destination_name} </h2>
                </div>
            </div>

            <div className="section-map">

                <div className="eta-container">

                    <ReactMapGL className="mapbox-map"
                        {...viewState}
                        width='100%'
                        height='100%'
                        mapStyle={theme.mapboxStyle}
                        mapboxAccessToken={"pk.eyJ1Ijoic2lnbmFsYm94IiwiYSI6ImNpeThza29oMjAwMTkyd3AzazYweGgxazgifQ.f7SV743q_ULYFNXeW7mc7g"}
                        onMove={evt => viewStateChange(evt.viewState)}
                        ref={map => mapRef.current = map}
                        mapOptions={{ logoPosition: 'bottom-left' }}
                        onMouseDown={onInteraction}
                        onTouchStart={onInteraction}
                        onWheel={onInteraction}
                        onClick={onInteraction}
                        attributionControl={false} // disable the default attribution
                    >

                        <NavigationControl style={theme.navigationControlStyle}/>

                        <TrainMarkers
                            currentTrains={displayLocos}
                            iconSize={iconSize}
                            currentTrain={rid}
                            TrainIcon={theme.TrainIcon}
                            onTrainMarkerClick={() => { }} />

                        <RecentreControl onClick={onRecentreClick} disabled={tracking} />

                        <Route currentTrain={rid}
                            routeLineColor={theme?.routeLineColor}
                            routeBorderColor={theme?.routeBorderColor}
                            delay={currentLoco?.delay} />

                    </ReactMapGL>

                </div>

            </div>

            <div className="section-text">
                <div className='padding-medium'></div>
                <h2 className='arrival-station-text'> {getEtaText(stop)} </h2>
                <div className='arrival-time-wrapper'> <h2 className='arrival-time'> {getArrivalTimeString(stop)}</h2></div>
            </div>

        </div>
    );
}



export default EtaContainer;
