import React  from 'react';

import './LnerStopsDot.css';

function LnerStopsDot() {
    return (
		<g>
			<circle cx="20" cy="20" r="15" fill="#009B83" fillOpacity="0.8" stroke="none"/>
			<circle cx="20.000" cy="20.000" r="8.0" fill="white" stroke="none"/>
		</g>
    );
}

export default LnerStopsDot;

        
{/* <g transform="scale(0.031 0.031) translate(-50, 0)">
<path className="st0" fill="#CE142E" d="M262,1128.2c0,0-5.5,23.3,6,62.4c11.4,38.9,8.7,39.5,29.3,78.4c13.1,24.8,18.6,36.4,22.4,49.6
	c2.2,7.5,3.8,18.1,6.7,26.7c10.2,30.1,0.2,29.8,49.6,37.8c22.8,3.7,169,27.1,324.4,27.1c2.8,0,5.6,0,8.3,0c2.3,0,4.6,0,6.9,0
	c39.2,0,80.5-1.8,116.9-3.8c41.7-2.3,78.3-6.5,110.3-9.8c50.9-5.2,87-11.6,97.6-13.5c52.5-9.6,39.1-6.3,50.9-38.3
	c3.1-8.5,3.9-17.5,6-25c3.8-13.2,9.4-25.2,22.6-50c20.6-38.9,18.3-39.7,29.4-78.6c11.1-38.9,6.3-63,6.3-63
	c-116.7,110.3-272.7,179.3-445.9,179.4h-0.7h0.7h-0.7h-0.7h0.7h-0.7C535.1,1307.5,378.7,1238.6,262,1128.2"/>
<path className="st1" fill="#CE142E" d="M1146.6,649.7c-0.1,1.2-0.4,3.8-1.1,7.3c-5.1,27.5-26,115.3-29.7,126c-4.2,12-12.5,30.8-40.7,43.9
	c-28.3,13.1-80.2,30.9-80.2,30.9s-14.8-19.6-20.6-26.4c0,0,20.8-106.4,29.1-168.1c0,0,10.9-1.5,27.2-5c17.1-3.6,40.2-9.5,62.7-18
	c43.9-16.7,37.1-16.7,43.4-13.1C1143,630.9,1147.2,638.7,1146.6,649.7 M974,631.9c-1.1,0.3-85.7,25.3-242.6,25.3
	c-7.3,0-14.7-0.1-22.1-0.2h-0.5h-0.5c-7.4,0.1-14.8,0.2-22.1,0.2c-157.2,0-241.8-25-242.6-25.3l-15.3-4.7
	c-19.7-150.1-26.3-318.7-26.3-318.7s71.7-33.9,147.5-50.6c64.5-14.3,148.6-12.3,159.4-12.3c10.8,0,94.9-2,159.4,12.3
	c75.7,16.7,147.5,50.6,147.5,50.6s-6.6,168.6-26.3,318.7L974,631.9z M443.2,834.9c-5.7,6.8-20.6,26.4-20.6,26.4
	s-51.9-17.9-80.2-30.9c-28.3-13.1-36.6-31.9-40.7-43.9c-3.7-10.7-24.7-98.5-29.7-126c-0.7-3.5-1-6.1-1.1-7.3
	c-0.5-11,3.7-18.8,9.9-22.5c6.3-3.7-0.5-3.7,43.4,13.1c22.5,8.6,45.5,14.4,62.7,18c16.3,3.5,27.2,4.9,27.2,4.9
	C422.4,728.4,443.2,834.9,443.2,834.9 M1239.6,671.1l-3.1-55.3l-27.4-330.4c0,0-0.7-33.3-11.1-51.8
	c-41.1-73.2-165.2-137.6-165.2-137.6c-7.3-3-12-0.7-12.8-0.2l-3.6,175.4c-25.3-11-81.5-33.8-140.8-46.9
	c-49.2-10.9-108.7-13.2-149.9-13.2c-5.7,0-10.4,0-13.5,0.1c-1.5,0-2.6,0-3.4,0s-1.9,0-3.4,0c-3.1,0-7.8-0.1-13.5-0.1
	c-41.2,0-100.7,2.3-149.9,13.2c-59.4,13.1-115.5,35.9-140.8,46.9l-3.5-170.1l0.2-5.4c0,0-4.9-3.4-12.1-0.4
	c0,0-124.7,64.1-165.8,137.3c-9.2,16.3-11.9,53.7-11.9,53.7l-27.2,329.1c0,0-1.7,20.4-3.2,38.4c-4.3,52.4-13.9,148.5-11.9,195.2
	c1,23.1,2.1,54.9,6.5,91.3c4,32.6,14,69.2,27.7,103.3c9.8,24.3,23.3,46.9,40.7,68.5c0,0,6.3,7.9,21.1,16.1
	c78,73.8,173.6,129,280.3,157.6l-108.7-621c0,0,95,29.2,275.2,26.6C889,694,984,664.8,984,664.8l-108.7,621.1
	c106.7-28.6,202.4-83.9,280.5-157.6c14.8-8.2,20.5-15.8,20.5-15.8c10.8-14,18.4-25.4,26.1-38.1c16.4-27.2,26.2-58.2,33.8-86.6
	c14.3-53.5,14.6-105.5,15.5-138.5C1253.2,790.7,1239.6,671.1,1239.6,671.1"/>
<path className="st2" fill="#CE142E" d="M708.8,691.4c-180.2,2.6-275.2-26.6-275.2-26.6l108.7,621c53.2,14.3,109.1,21.9,166.9,21.9
	c57.5,0,113.2-7.6,166.1-21.8L984,664.8C984,664.8,888.9,694,708.8,691.4"/>
<path className="st3" fill="#FFFFCB" d="M1136.7,627.2c-6.3-3.7,0.5-3.7-43.4,13.1c-22.5,8.6-45.5,14.4-62.7,18c-16.3,3.5-27.2,5-27.2,5
	c-8.4,61.7-29.1,168.1-29.1,168.1c5.7,6.8,20.6,26.4,20.6,26.4s51.9-17.9,80.2-30.9c28.2-13.1,36.6-31.9,40.7-43.9
	c3.7-10.7,24.6-98.5,29.7-125.9c0.7-3.5,1.1-6.1,1.1-7.3C1147.1,638.7,1143,630.9,1136.7,627.2"/>
<path className="st3" fill="#FFFFCB"  d="M280.7,630.7c6.3-3.7-0.5-3.7,43.4,13.1c22.5,8.6,45.5,14.4,62.7,18c16.3,3.5,27.2,5,27.2,5
	c8.4,61.7,29.1,168.1,29.1,168.1c-5.7,6.8-20.6,26.4-20.6,26.4s-51.9-17.9-80.2-30.9c-28.2-13.1-36.6-31.9-40.7-43.9
	C298,775.8,277,688,271.9,660.5c-0.7-3.5-1-6.1-1.1-7.3C270.3,642.2,274.5,634.3,280.7,630.7"/>
<path className="st3" fill="#ffff" d="M443.6,631.9c0.8,0.2,85.4,25.3,242.6,25.3c7.3,0,14.7-0.1,22.1-0.2l0.5,0l0.5,0c7.4,0.1,14.8,0.2,22.1,0.2
	c156.9,0,241.4-24.9,242.6-25.3l15.3-4.7c19.7-150.1,26.3-318.7,26.3-318.7s-71.7-33.9-147.5-50.6
	c-64.5-14.3-148.6-12.3-159.4-12.3v-0.4v0.4c-10.8,0-94.9-2-159.4,12.3c-75.7,16.7-147.5,50.6-147.5,50.6s6.6,168.6,26.4,318.7
	L443.6,631.9z"/>
<path className="st0" fill="#CE142E" d="M541.9,224.3c49.2-10.9,108.7-13.2,149.9-13.2c5.7,0,10.4,0,13.5,0.1c1.5,0,2.6,0,3.4,0c0.8,0,1.9,0,3.4,0
	c3.1,0,7.8-0.1,13.5-0.1c41.2,0,100.8,2.3,149.9,13.2c59.4,13.1,115.5,35.9,140.8,46.9l3.6-175.5l-0.4,0.4c0,0-31.1-23.1-74.1-40.6
	c-17.3-7.1-25.6-10.1-33.4-12.7c-11.1-3.8-19.2-6.1-36.2-11.1c-17.7-5.3-36.8-9.4-52.5-12.6C763.4,6.6,721.8,7,708.8,7.1
	c-11.3,0.1-30.2-0.1-61.5,3.5c-29.9,3.4-71.4,10.4-122.5,25.8c-79.1,23.9-127,59.3-127,59.3l-0.2,5.4l3.5,170.1
	C426.4,260.2,482.6,237.5,541.9,224.3"/>
</g> */}
