import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import './Key.css';
import TrainIcon
  from 'components/TrainIcon/TrainIcon';



function Key(props) {

  const { layout, greenIcon, amberIcon, redIcon } = props

  return (
    <div className="Key">
      <div className='key-container'>
        <h2 className> Map key </h2>
        <div className='padding-small'></div>
        <div className='row'>
          <div className="train-icon-div" >
            {greenIcon}
          </div>
          <div className='train-icon-description'> <p> On time </p> </div>
        </div>
        <div className='row'>
          <div className="train-icon-div" >
            {amberIcon}
          </div>
          <div className='train-icon-description'> <p> 1-10 mins delay </p> </div>
        </div>
        <div className='row'>
          <div className="train-icon-div" >
            {redIcon}
          </div>
          <div className='train-icon-description'> <p> 10+ mins delay </p> </div>
        </div>

        <div className='direction-description'>
          <p> For a moving train, the arrow indicates the direction of travel.
            When a train is stationary the marker arrow
            points north. </p>
        </div>
      </div>

    </div>
  )

}

const iconSize = 30;

Key.propTypes = {
  layout: PropTypes.string,
}

const icon = (<TrainIcon className="train-icon" delay={0} heading={45} size={iconSize} />)

Key.defaultProps = {
  greenIcon: icon,
  amberIcon: <TrainIcon className="train-icon" delay={5} heading={45} size={iconSize} />,
  redIcon: <TrainIcon className="train-icon" delay={15} heading={45} size={iconSize} />
};


export default memo(Key)
