import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import './TrainlineKey.css';
import TrainlineTrainIcon from '../TrainlineTrainIcon';
import { useTranslation } from 'react-i18next'


function TrainlineKey (props){

  const {layout, greenIcon, amberIcon, redIcon, otherIcon} = props

   const { t, i18n } = useTranslation();
   
    return (
      <div className="TrainlineKey">
        <div className='key-container'>
          <h2 > {t('key.key_title')}</h2>
    
          <div className='row'>
            <div className="train-icon-div" >
              {greenIcon}
            </div>
            <div className='train-icon-description'> <p> {t('key.green_text')} </p> </div>
          </div>
          <div className='row'>
            <div className="train-icon-div" >
              {amberIcon}
            </div>
            <div className='train-icon-description'> <p> {t('key.amber_text')}  </p> </div>
          </div>
          <div className='row'>
            <div className="train-icon-div" >
              {redIcon}
            </div>
            <div className='train-icon-description'> <p> {t('key.red_text')}  </p> </div>
          </div>

          <div className='direction-description'>
            <p> {t('key.info_text')}</p> 
          </div>
        </div>
           
      </div>
  )
   
}


const iconSize = 30;

TrainlineKey.propTypes = {
  layout: PropTypes.string,
}


TrainlineKey.defaultProps = {
  greenIcon: <TrainlineTrainIcon className="train-icon" delay={0} heading={45} size={iconSize} tocCode='AW'/>,
  amberIcon: <TrainlineTrainIcon className="train-icon" delay={5} heading={45} size={iconSize} tocCode='AW'/>,
  redIcon: <TrainlineTrainIcon className="train-icon" delay={15} heading={45} size={iconSize} tocCode='AW'/>,
};


export default memo(TrainlineKey)
