import {useEffect} from 'react';
import i18n from 'i18n';


function useToggleLayers(map) {
  // State and setters for debounced value
  
  useEffect(() => {

    if( map?.getLayer("stations_welsh") ){

        if(i18n.language === 'en'){
          map.setLayoutProperty('stations_welsh', 'visibility', 'none')
        }
        if(i18n.language === 'cy'){
          map.setLayoutProperty('stations_welsh', 'visibility', 'visible')
        }
    }
}, [map, i18n.language]);

}
 

export default useToggleLayers
