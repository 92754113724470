import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import './HomeWidget.css';
import Logo from 'components/Logo';
import SignalboxLightLogo from './signalbox_logo_light.svg';
import useModal from 'hooks/useModal';
import LnerLine from './lner_horizontal_line.svg'
import LnerLogo from './lner_logo_white.svg'
import AvantiLogo from './awc_light.png';
import TfwLogo from './tfw_logo.png'
import SignalboxLogo from './signalbox_logo_name_light.png';



function HomeWidget (props){

  const {
    setModalContent,
    setShowModal,
    themeName,
  } = props

  const {MODAL_CONTENT} = useModal()
  
  let logo = SignalboxLogo

  if(themeName=='lner'){
    logo = LnerLogo
  }
  if(themeName=='avanti'){
    logo = AvantiLogo
  }
  if(themeName=='tfw'){
    logo = TfwLogo
  }

   
    return (
      <div className="HomeWidget">
    
          <div className='container'>
              <div className='logo-wrapper'>
                <img src={logo} alt="Signalbox Logo" /> 
              </div> 
               <div className='text-wrapper'>
                {/* <p>Find, track and share the train you are on and view trains on a live map.</p> */}
              </div>  

              
                <div className='button-wrapper'>
                <div className='description'> Search for live trains on the rail network </div>
                   <button className="button-light" onClick={()=>setModalContent(MODAL_CONTENT.SEARCH)} > Filter trains </button>
                </div>

                
                <div className='button-wrapper'>
                <div className='description'> Automatically find the train you are on </div>
                  <button className="button-light" onClick={()=>setModalContent(MODAL_CONTENT.FIND_MY_TRAIN)} > Find my train 
                 
                  </button>
                </div>

                <div className='button-wrapper'>
                <div className='description'> View live train locations on a map </div>
                  <button className="button-light"  onClick={()=>{setShowModal(false); setModalContent(MODAL_CONTENT.NONE)}}> Train map </button>
                </div>

                {/* <div className='lner-line-wrapper'>
                  <img className='lner-line' src={LnerLine} alt="Lner line" />
                 </div> */}

                <div className='attribution'>
                   <p> Powered by </p> <img src={SignalboxLightLogo} alt="Signalbox Logo" />  
                </div>

            </div>
      
          
      </div>
  )
   
}

const iconSize = 30;

HomeWidget.propTypes = {
  layout: PropTypes.string,
}

HomeWidget.defaultProps = {
  logo: SignalboxLogo,
};


export default memo(HomeWidget)
