import React, { useEffect, useState } from "react";

/*
 This is a custom hook to contain state variables dictating what information is 
 is shown in the maps modal
 */


function useModal() {

    // modal content "key, stops, search, info or none"

    const [modalContent, setModalContent] = useState("home")
    const [showModal, setShowModal] = useState(false)

    const MODAL_CONTENT = {
        NONE: "",
        HOME: "home",
        KEY: 'key',
        STOPS: 'stops',
        SEARCH: 'search',
        INFO: 'info',
        FIND_MY_TRAIN: 'find_my_train'
    }

    return {
        showModal,
        setShowModal,
        modalContent,
        setModalContent,
        MODAL_CONTENT
    };
}

export default useModal;