import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './InfoWidget.scss'
import DefaultKey from 'components/InfoWidget/Key';
import { useTranslation } from 'react-i18next'

function InfoWidget({ className, ...props }) {

  const {
    Key = DefaultKey,
  } = props;

  const { t, i18n } = useTranslation();


  return (
    <div className='InfoWidget'>

      <div className='body'>
        <div className='container'>



          <Key />

          <h2> {t('info.about_title')} </h2>
          <p> {t('info.about_text')}  </p>
          <h2 >{t('info.feedback_title')}</h2>
          <p> {t('info.feedback_text')} </p>

          <h2> {t('info.cookies_title')}</h2>
          <p> {t('info.cookies_text')}  </p>

          <h2> {t('info.licensing_title')}</h2>
          <p> {t('info.licensing_text')}  </p>

          <h2> {t('info.troubleshooting_title')}</h2>
          <p>  {t('info.troubleshooting_text')} </p>

          <h2> {t('info.safety_title')}</h2>
          <p>{t('info.safety_text')}</p>

        </div>
      </div>
    </div>
  )
}

InfoWidget.propTypes = {
  className: PropTypes.string
}

export default memo(InfoWidget)
