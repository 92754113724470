import React, {useState} from 'react';

import logo from "./logo.png";

import './TrainlineLogo.css';

function TrainlineLogo() {

    return (
            <div className="TrainlineLogo">
                    <img src={logo} alt={"Tfw Logo"}/>
            </div>
           
    );
}

export default TrainlineLogo;
