import React  from 'react';

function TrainlineStopsDot() {
    return (
        <g >
            <circle cx="20" cy="20" r="14" fill="#FFFFFF" stroke="None"/>
            <circle cx="20" cy="20" r="14" stroke="#1F1F1F" fill="None" strokeWidth="4"/>
        </g>

    );
}

export default TrainlineStopsDot;
