import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import './FindMyTrainButton.css';

function FindMyTrainButton(props) {
    const { onClick, className, selected } = props;

    return (
        <div className={classnames('FindMyTrainButton', className)} onClick={_ => onClick && onClick()}>
            <div className={classnames('button-small', { "selected": selected })}>
                <LocationSearchingIcon />
            </div>

        </div>
    );
}

FindMyTrainButton.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string
};

export default FindMyTrainButton;
