import { useCallback } from 'react'

export default function useStationSelector () {
  const optionsReducer = useCallback(stops => {
    const b = stops?.reduce((acc, { crs, name }) => ([
      ...acc,
      {
        label: name,
        value: crs
      }
    ]
    
    ), [])

    // sort in alphabetical order.
    const d = b.sort(function(a, b) {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  });
  
  return d

  }
  
  , [])
  

  return {
    optionsReducer
  }
}
