import React from 'react';

import logo from "./logo.png";

import './TfwLogo.css';

function TfwLogo() {

    return (
            <div className="TfwLogo">
                    <img src={logo} alt={"Tfw Logo"}/>
            </div>
           
    );
}

export default TfwLogo;
