import React  from 'react';

function TfwStopsDot() {
    return (
        <g >
            <circle cx="20" cy="20" r="14" fill="#AF1E0E"/>
            <circle cx="20" cy="20" r="14" fill="black" fillOpacity="0.2"/>
            <circle cx="20" cy="20" r="14" stroke="#F5F5F5" strokeWidth="2"/>
            <circle cx="20" cy="20" r="10" fill="#AF1E0E"/>
            <circle cx="20" cy="20" r="10" fill="black" fillOpacity="0.2"/>
            <circle cx="20" cy="20" r="10" stroke="#F5F5F5" strokeWidth="2"/>
    </g>

    );
}

export default TfwStopsDot;
