import React, { useEffect, createContext, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MapContainer from "containers/MapContainer";
import EtaContainer from "containers/EtaContainer";
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import 'index.css';
import './i18n';
import ReactGA from 'react-ga';
import { createRoot } from 'react-dom/client';

import ThemeContext from 'Contexts'
import DefaultTheme from "themes/default/DefaultTheme";


const TRACKING_ID = "UA-137047576-2"; // OUR_TRACKING_ID (for google analytics)

const queryClient = new QueryClient()


function App() {

    const [theme, setTheme] = useState(DefaultTheme)

    ReactGA.initialize(TRACKING_ID);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            <QueryClientProvider client={queryClient}>
                <div className="App">
                    <Router>
                        <Routes>
                            <Route exact path="/" element={<MapContainer />} />
                            <Route exact path="/eta/:rid/:crs" element={<EtaContainer />} />
                        </Routes>
                    </Router>
                </div>
            </QueryClientProvider>
        </ThemeContext.Provider>
    );
}

const container = document.getElementById('root');
const root = createRoot(container)
root.render(<React.StrictMode>
    <App />
</React.StrictMode>);

