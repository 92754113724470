
import React from "react"
import "./TfwNoTrains.css"
import logo from "./error_icon.png";



// https://codepen.io/rhulkashyap/pen/ONVPXb

function TfwNoTrains(props) {

     
    return (
        <div className="TfwNoTrains">
             <img src={logo} alt={"No trains icon"}/>
        </div>
    )
}


export default TfwNoTrains;