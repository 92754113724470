import React, {useEffect, useState} from "react";
import { useQuery } from 'react-query'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import {apiBaseUrl} from "../../utils/utils";


function useStations() {

    const base_url = apiBaseUrl();

    const { t, i18n } = useTranslation();

    // Welsh language hack
    let lang_arg='';
    if(i18n.language=='cy'){
        lang_arg='?lang=cy'
    }

    const { isLoading,
        isError,
        data: stations,
        error: stationsError } = useQuery(['stations'], () => { return axios.get(`${base_url}/api/stations${lang_arg}`) })

    const findStation = function(data, crs){
        const found = data?.data.stations.find(station => station.crs_code == crs)
        // alert(found)
        return found
    }

    return {
        isLoading,
        isError,
        stations,
        findStation
    };
}

export default useStations;
