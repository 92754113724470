import React from 'react'
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import './LanguageToggle.css';


export function LanguageToggle(props) {
    
    const {lang, setLang} = props;

    const { t, i18n } = useTranslation();

    const toggleLanguage = function(){
        if(lang=='cy'){
            setLang(undefined)
            i18n.changeLanguage('en')
        }else{
            setLang('cy')
            i18n.changeLanguage('cy')
        }
    }

    return (
        <div className='LanguageToggle' onClick={() => toggleLanguage()}>
            <div className='language-text'>                
                {lang=='cy' ? 'English' : 'Cymraeg'}
            </div>
        </div>
    );
}

LanguageToggle.propTypes = {
    layout: PropTypes.string,

};

LanguageToggle.defaultProps = {

};
