import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import './LnerKey.css';
import LnerTrainIcon from '../LnerTrainIcon';


function LnerKey (props){

  const {layout, greenIcon, amberIcon, redIcon, otherIcon} = props
   
    return (
      <div className="LnerKey">
        <div className='container'>
          <h1 className> Map key </h1>

          <div className='padding-small'></div>
          <h2 className='padding-medium'>LNER trains</h2>
    
          <div className='row'>
            <div className="train-icon-div" >
              {greenIcon}
            </div>
            <div className='train-icon-description'> <p> On time </p> </div>
          </div>
          <div className='row'>
            <div className="train-icon-div" >
              {amberIcon}
            </div>
            <div className='train-icon-description'> <p> 1-10 mins delay </p> </div>
          </div>
          <div className='row'>
            <div className="train-icon-div" >
              {redIcon}
            </div>
            <div className='train-icon-description'> <p> 10+ mins delay </p> </div>
          </div>

          

          <h2 className='padding-medium'>Non-LNER trains</h2>

          <div className='row'>
            <div className="train-icon-div" >
              {otherIcon}
            </div>
            <div className='train-icon-description'> <p> Delay not shown </p> </div>
          </div>

          <div className='direction-description'>
            <p> For a moving train, the arrow indicates the direction of travel.
               When a train is stationary the marker arrow 
              points north. </p> 
          </div>
        </div>
           
      </div>
  )
   
}

const iconSize = 30;

LnerKey.propTypes = {
  layout: PropTypes.string,
}


LnerKey.defaultProps = {
  greenIcon: <LnerTrainIcon className="train-icon" delay={0} heading={45} size={iconSize} tocCode='GR'/>,
  amberIcon: <LnerTrainIcon className="train-icon" delay={5} heading={45} size={iconSize} tocCode='GR'/>,
  redIcon: <LnerTrainIcon className="train-icon" delay={15} heading={45} size={iconSize} tocCode='GR'/>,
  otherIcon:  <LnerTrainIcon className="train-icon" delay={15} heading={45} size={iconSize} />
};


export default memo(LnerKey)
