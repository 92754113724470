import React, {Fragment, useLayoutEffect} from 'react';
import StopsDrawing from "components/Train/StopsDrawing/StopsDrawing.js";
import './Stops.css';
import {DateTime} from "luxon";
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

function Stops(props) {

    const {CustomDot, 
        scrollToDot = false
        } = props;

    const { t, i18n } = useTranslation();

    useLayoutEffect(() => {

        // When the stops are shown, this moves the location dot into view on rid change.
        let locationDot = document.getElementById('nearest-stop');
        if(locationDot !== null && scrollToDot){
         locationDot.scrollIntoView({ block: 'nearest', inline: 'start' }); 
        }
    }, [props.data]);

    const actualTime = function (stop) {
        if (stop.actual_departure === null) {
            return DateTime.fromISO(stop.actual_arrival);
        } else {
            return DateTime.fromISO(stop.actual_departure);
        }
    }

    const scheduledTime = function (stop) {
        if (stop.departure === null) {
            return DateTime.fromISO(stop.arrival);
        } else {
            return DateTime.fromISO(stop.departure);
        }
    }

    const isDelayed = function (stop) {
        const mins = actualTime(stop).diff(scheduledTime(stop), 'minutes');
        return mins > 0;
    }

    const isAtStation = function (stop) {
        let currentTime = DateTime.now().setZone('Europe/London');
        let actualArrival = (stop.actual_arrival === null) ? null : DateTime.fromISO(stop.actual_arrival);
        let actualDeparture = (stop.actual_departure === null) ? null : DateTime.fromISO(stop.actual_departure);

        //check whether at origin
        if (actualArrival === null && currentTime < actualDeparture) {
            return true;
        }

        //check whether arrived at destination
        if (actualDeparture === null && currentTime > actualArrival) {
            return true;
        }

        return currentTime > actualArrival && currentTime < actualDeparture;
    }

    const dotPosition = function () {
        //check at stop and return index of stop.
        let index = props.stops.findIndex(isAtStation);
        let currentTime = DateTime.now().setZone('Europe/London');

        if (index !== -1) {
            return index / (props.stops.length - 1);
        }

        for (let i = 0; i < (props.stops.length - 1); i++) {
            let lastStop = props.stops[i]
            let nextStop = props.stops[i + 1]
            let lastDeparture = DateTime.fromISO(lastStop.actual_departure);
            let nextArrival = DateTime.fromISO(nextStop.actual_arrival);
            if (currentTime > lastDeparture && currentTime < nextArrival) {
                const betweenStops = nextArrival.diff(lastDeparture);
                const sinceLastStop = currentTime.diff(lastDeparture);
                const proportionComplete = sinceLastStop.as('seconds') / betweenStops.as('seconds');
                // alert(proportionComplete)
                return i / (props.stops.length - 1) + proportionComplete / (props.stops.length - 1);
                //(proportionComplete * (1/props.stops.length)
            }
        }
        return -1
    }

    if (props.loading) {
        return null
    }

    if (!props.stops) {
        return null
    }



    return (
        <div className="Stops">

            {/*This adds schedule schematic*/}
            <div className="left-column" style={{"gridRowStart": "1", "gridRowEnd": String(props.stops.length + 1)}}>
                <StopsDrawing numberStops={props.stops.length} dotPosition={dotPosition()} CustomDot={CustomDot}/>
            </div>

            {/* {alert("dot position: " + dotPosition()*props.stops.length)} */}

            {/* This adds the text */}
            {props.stops.map(
                (stop, index) => (

                    <Fragment key={"g" + index}>

                        <div id={Math.round(dotPosition() * props.stops.length - 1) === index ? "nearest-stop" : null}
                             className="middle-column" style={{"gridRow": index + 1}}>
                            <h2 className='stop-name'> {stop.name} </h2>
                            <h3 className='platform'> {t('train.platform')} {stop.platform} </h3>
                        </div>
                        <div className="right-column" style={{"gridRow": index + 1}}>
                            <h2 className="time "> {scheduledTime(stop).toFormat("HH:mm")} </h2>
                            <h2 className={classnames("time", isDelayed(stop) ? 'delayed' : " ")}>
                                {isDelayed(stop) ? actualTime(stop).toFormat("HH:mm") : t('on_time')}
                            </h2>
                        </div>
                    </Fragment>
                )
            )}
        </div>
    );
}
;
export default Stops;
