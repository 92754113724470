/**
 * Calculate the size of the train icons for a given zoom level.
 * @param viewport the MapBox map viewport object.
 * @returns {number} the size in pixels.
 */
export function calculateIconSize(viewport) {
    const zoom = viewport.zoom;

    if (zoom < 6) {
        return 8;
    }

    if (zoom < 8) {
        return 19;
    }

    if (zoom < 10) {
        return 27
    }

    if (zoom < 12) {
        return 38
    }

    return 40;
}

export function parseLocationParameter(value) {
    const paramArray = value.substring(1).split(',');
    const center = {lat: parseFloat(paramArray[0]), lng: parseFloat(paramArray[1])};
    const zoom = parseInt(paramArray[2].substr(0, paramArray[2].length - 1));
    return {center, zoom};
}


export function createLocationParameter(aLocation) {
    //from a location object forms a the parameter string
    const locationString = "@"
        + aLocation.latitude.toString().substr(0, 8)
        + ","
        + aLocation.longitude.toString().substr(0, 8)
        + ","
        + aLocation.zoom.toString().substr(0, 5)
        + "Z";
    return locationString
}

export function createQueryString(search) {
    //from a location object forms a the parameter string
    const q = Object.entries(search).reduce((acc, [k, v]) => {
        if (acc !== "") {
            acc += "&";
        }
        acc += k + "=" + v;
        return acc;
    }, "");
    return q
}

export function parseViewParameter(value) {
    const paramArray = value.substring(1).split(',');
    const viewport = {
        'latitude': parseFloat(paramArray[0]),
        'longitude': parseFloat(paramArray[1]),
        'zoom': parseInt(paramArray[2].substr(0, paramArray[2].length - 1))
    }
    return viewport
}

export const parseGeofenceParameter = function (value) {

    const paramArray = value.split(',');
    const geofence = {
        lat: parseFloat(paramArray[0]),
        lon: parseFloat(paramArray[1]),
        radius: parseFloat(paramArray[2])
    };
    return geofence;
}


export const createGeofenceParameter = function (d) {
    const locationString = ""
        + d.lat.toString().substr(0, 8)
        + ","
        + d.lon.toString().substr(0, 8)
        + ","
        + d.radius.toString().substr(0, 5)
    return locationString
}

export const parseArea = function (d) {
    let area = {}
    let error = false
    try {
        area['lat'] = parseFloat(d.lat)
        area['lon'] = parseFloat(d.lon)
        area['radius'] = parseFloat(d.radius)

    } catch {
        area = undefined
        error = true
    }
    if (d === undefined || !area['lat'] || !area['lon']) {
        area = undefined;
        error = true;
    }
    return {error, area}
}

export const apiBaseUrl = () => {
    if (document.API_BASE_URL !== undefined) {
         return document.API_BASE_URL;
    }
    return process.env.REACT_APP_API_BASE_URL;
}


