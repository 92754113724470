// Component to open share tray etc.. 

import React, {useState} from "react"
import "./EtaSharing.css"
import useEtaSharing from "./useEtaSharing"
import { useTranslation } from 'react-i18next'


function EtaSharing(props) {
  // pass stop and crs. 

  const {crs, train} = props

  const { t, i18n } = useTranslation();

  const {onShareClick, getEtaText, getStop} = useEtaSharing()

  const [showToast, setShowToast] = useState(false)

  const stop = getStop(train.stops, crs)
  var shareUrl = window.location.href.split('?')[0] + "eta/" + train.rid + "/" + stop.crs;

  // shows a toast if sharing not permitted. 
  const onShare = function(){
    const succeeded = onShareClick(stop, shareUrl)
    if (!succeeded){
      setShowToast(true)
      setTimeout(() => setShowToast(false), 6000)
    }
  }

    return (

        <div className="EtaSharing">

          <div className='padding-small'>
          <h2 className="padding-medium text-center"> {getEtaText(stop)}  </h2>  
          </div>
          <button className = "button-dark" onClick={onShare} > {t('eta_sharing.share_my_journey')} </button>

          {showToast&&
          ( 
            <div className = 'toast'>
              
              {t('eta_sharing.share_tray_not_supported')}
            </div> 
          )
          }
   
        </div>
    )
}


export default EtaSharing;





