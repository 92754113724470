import React, { useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import './SearchWidget.css';
import SearchResult from './SearchResult'
import SearchForm from './SearchForm'
import { parseArea } from 'utils/utils';
import useTrainOperators from './useTrainOperators';
import useStations from './useStations'
import BackButton from 'components/BackButton';
import Train from 'components/Train';
import { useTranslation } from 'react-i18next'



function SearchWidget(props) {
    const {
        setToc,
        toc,
        setFrom,
        from,
        setTo,
        to,
        area,
        setArea,
        searchTrains,
        searchError,
        searchLoading,
        setCurrentTrain,
        boundingBox,
        setTracking,
        tracking,
        currentTrain,
        customDot
    } = props;

    const { t, i18n } = useTranslation();

    const [page, setPage] = useState(0);

    const [selectedToc, setSelectedToc] = useState()
    const [selectedFromStation, setSelectedFromStation] = useState()
    const [selectedToStation, setSelectedToStation] = useState()
    const [selectedArea, setSelectedArea] = useState()


    const { isLoading: operatorsIsLoading,
        isError: operatorsIsError,
        operators: operatorsData,
        findOperator } = useTrainOperators()

    const {
        isLoading: stationsIsLoading,
        isError: stationsIsError,
        stations,
        error: stationsError,
        findStation } = useStations()


    // populate selected values 
    useLayoutEffect(() => {
        if (toc) {
            setSelectedToc({ value: toc, 'label': findOperator(operatorsData, toc)?.company_name })
        }
    }, [toc, operatorsData])
    // populate selected values 
    useLayoutEffect(() => {
        if (to) {
            setSelectedToStation({ value: to, 'label': findStation(stations, to)?.name })
        }
    }, [to, stations])

    useLayoutEffect(() => {
        if (from) {
            setSelectedFromStation({ value: from, 'label': findStation(stations, from)?.name })
        }
    }, [from, stations])

    useLayoutEffect(() => {
        if (area) {
            setSelectedArea(area)
        }
    }, [area])

    const onSelectTrain = function(rid){
        setCurrentTrain(rid);
        setPage(2); // current train page
    }
    
    const previousPage = function(){
        setPage(currentPage => currentPage - 1)
    }

    const nextPage = function(){
        setPage(currentPage => currentPage + 1)
    }


    const handleApplySearch = function () {
        setToc(selectedToc?.value)
        setFrom(selectedFromStation?.value)
        setTo(selectedToStation?.value)
        const { area, error } = parseArea(selectedArea)
        setArea(area)
        setPage(1); // page 2 is the results page
    }

    const handleReset = function () {
        setToc(undefined)
        setFrom(undefined)
        setTo(undefined)
        setArea(undefined)
        setSelectedToc(null)
        setSelectedFromStation(null)
        setSelectedToStation(null)
        setSelectedArea(undefined)

    }


    if (stationsIsError || operatorsIsError || searchError) {
        return (
            <div className='SearchWidget'>
                <div className='container'>
                    <h2 className='center'> Error </h2>
                </div>
            </div>
        )
    }
    if (operatorsIsLoading || stationsIsLoading || searchLoading) {
        return (
            <div className='SearchWidget'>
                <div className='container'>
                        <h2 className='center'> {t('loading')}</h2>
                </div>
            </div>
        )
    }

    if (page===0){
        return (
            <div className='SearchWidget'>

                <div className='container'>
                    {searchTrains!==undefined &&
                        <div className='header-div'>
                           <BackButton label={t('search_widget.view_results')} onClick={nextPage}/>
                       </div >
                    }
                    <SearchForm
                        trainOperators={operatorsData?.data}
                        stations={stations?.data}
                        setSelectedToc={setSelectedToc}
                        selectedToc={selectedToc}
                        selectedFromStation={selectedFromStation}
                        setSelectedFromStation={setSelectedFromStation}
                        selectedToStation={selectedToStation}
                        setSelectedToStation={setSelectedToStation}
                        selectedArea={selectedArea}
                        setSelectedArea={setSelectedArea}

                        handleApplySearch={handleApplySearch}
                        handleReset={handleReset}
                    />
                 </div>
            </div>
        )
    }

    if (page===1){
        return(
            <div className='SearchWidget'>
            <div className='container'>
                <div className='header-div'>
                    <BackButton label={t('search_widget.edit')} onClick={previousPage}/>
                </div >

                <SearchResult
                    handleReset={handleReset}
                    trainOperator={findOperator(operatorsData, toc)?.company_name}
                    fromStation={findStation(stations, from)?.name}
                    toStation={findStation(stations, to)?.name}
                    area={area}

                    error={searchError}
                    searchTrains={searchTrains}

                    onResetClick={handleReset}
                    onSelectTrain={onSelectTrain}
                    setTracking={setTracking}
                    tracking={tracking}
                    
                    currentTrain={currentTrain}
                />
            </div>
        </div>
        )
  
    }

    if(page===2){
        return (
            <div className="SearchWidget">
                 {/* <div className='container'> */}
                 {/* <div className='header-div'>
                    <BackButton label={"Back to trains"} onClick={previousPage}/>
                </div > */}

                 <Train 
                    onBackClick={previousPage}
                    labe
                    currentTrain={currentTrain} 
                    customDot={customDot}
                    // onBackClick={onBackToTrains} 
                    showRecentre={true}
                    setTracking={setTracking} 
                    tracking={tracking}
                    showEta={true}
                    />
                 </div>
 
        )
    }


}

SearchWidget.propTypes = {
    delay: PropTypes.number,
    label: PropTypes.string,
    setToc: PropTypes.func
};

SearchWidget.defaultProps = {
    id: "",
    heading: 0,
    toc: undefined,
};

export default React.memo(SearchWidget);
