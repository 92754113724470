
import TfwStopsDot from "themes/tfw/components/TfwStopsDot";
import TfwTrainIcon from "themes/tfw/components/TfwTrainIcon";
import TfwLogo from 'themes/tfw/components/TfwLogo'
import TfwKey from 'themes/tfw/components/TfwKey'
import TfwHomeWidget from 'themes/tfw/components/TfwHomeWidget'
import TfwNoTrains from 'themes/tfw/components/TfwNoTrains'
import TfwLayerIcon from "./components/TfwLayerIcon/TfwLayerIcon";

const TfwTheme = {
        name: 'tfw',
        toc: 'AW',
        StopsDot: TfwStopsDot,
        TrainIcon: TfwTrainIcon,
        TrainLayerIcon: TfwLayerIcon,
        Logo: TfwLogo,
        Key: TfwKey,
        HomeWidget: TfwHomeWidget,
        NoTrains: TfwNoTrains,
        viewportDefaults: {
            latitude: 52.45003,
            longitude: -4.06383,
            zoom: 7.1,
        },

        routeLineColor: undefined,
        routeBorderColor: '#005059',

        mapboxStyle: "mapbox://styles/signalbox/clhmby8av01o701qyezno6q4b",

        navigationControlStyle: { right: 12, top: 64 },
        geolocateControlStyle: { right: 12, top: 198 },

        cssVariables: {
            '--color-primary': '#AF1E0E',
            '--color-primary-hover': '#AF1E0E',
            '--color-primary-lighter': '#E50303',
            '--color-secondary': 'white',

            '--color-hyperlink': '#003DA6',
            '--color-ripple': ' rgba(140, 24, 11, 0.8)',

            '--color-border': '#606060',

            '--color-rag-red': '#FD5057',
            '--color-rag-amber': '#FDB84E',
            '--color-rag-green': '#14A28C',
            '--color-rag-neutral': '#A6A6A6',

            '--color-stops-line': '#AE1E0E',
            '--color-stops-end-dot': "#AE1E0E",
            '--color-stops-end-dot-center': "#F6F6F6",

            /* Menu colors */
            '--color-menu-buttons': '#440F11', /*LNER Oxblood */
            '--color-menu-buttons-select': '#333333', /* LNER  prime red */
            '--color-menu-buttons-select-background': '#FAE7EA',

            '--color-text-heading': '#000000',
            '--color-text-subheading': '#000000',
            '--color-text-paragraph': '#333333',

            '--font-family-primary': "wales-sans-body-regular",
            '--font-family-secondary': "wales-sans-body-medium",

            '--font-button': '16px var(--font-family-secondary), sans-serif',

            /* Overlays */
            '--overlay-border': '1px solid #DADADA',
            '--overlay-border-radius': '6px',
            '--overlay-box-shadow': '0px 4px 8px rgba(0, 0, 0, 0.12), 0px 16px 32px rgba(0, 0, 0, 0.08)',
            '--border-internal': '1px solid #E5E5E5',

            '--map-border': 'none'

        }

}
    
export default TfwTheme