import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import './TrainlineHomeWidget.css';
import SignalboxLightLogo from './signalbox_attribution_dark.svg';
import useModal from 'hooks/useModal';
// import TfwWideLogo from './tfw_wide_logo.svg';
import TrainlineWideLogo from './logo.png';
import { useTranslation } from 'react-i18next'



function TrainlineHomeWidget (props){

  const {
    setModalContent,
    setShowModal,
  } = props

  const { t, i18n } = useTranslation();

  const {MODAL_CONTENT} = useModal()
  
   
    return (
      <div className="TrainlineHomeWidget">

   
              <div className='logo-wrapper'>
              
                 <img src={TrainlineWideLogo} alt="Trainline Logo" /> 
              </div> 
             
              <div className='button-container'>

                <div className='button-wrapper'>
                <div className='description'> {t('home.search_trains_label')} </div>
                   <button className="button-dark" onClick={()=>setModalContent(MODAL_CONTENT.SEARCH)} > {t('home.search_trains')} </button>
                </div>

                
                <div className='button-wrapper'>
                <div className='description'> {t('home.find_my_train_label')} </div>                  
                <button className="button-dark" onClick={()=>setModalContent(MODAL_CONTENT.FIND_MY_TRAIN)} >
                      {t('home.find_my_train')} 
                  </button>
                </div>

                <div className='button-wrapper'>
                <div className='description'>  {t('home.train_map_label')}  </div>
                  <button className="button-dark"  onClick={()=>{setShowModal(false); setModalContent(MODAL_CONTENT.NONE)}}> 
                  {t('home.train_map')}
                   </button>
                </div>

                <div className='attribution'>
                   <p> {t('home.powered_by')}</p> <img src={SignalboxLightLogo} alt="Signalbox Logo" />  
                </div>

            </div>

   
      
          
      </div>
  )
   
}

const iconSize = 30;

TrainlineHomeWidget.propTypes = {
  layout: PropTypes.string,
}

TrainlineHomeWidget.defaultProps = {
};


export default memo(TrainlineHomeWidget)
