import React  from 'react';

function AvantiStopsDot() {
    return (
        <g id="ICONS" transform="matrix(.13333 0 0 .13333 -10.628 -10.667)">
            <path className="st14"
                  d="m229.71 80c-82.84 0-150 67.2-150 150 0 82.84 67.16 150 150 150s150-67.16 150-150c0-82.8-67.17-150-150-150z"
                  fill="#004f59"/>
            <path className="st0"
                  d="m281.79 228.5c2.37-0.52 7.07-0.09 6.1 7.57-6.09 57.04-70.47 12.94-6.1-7.57m-96.93-74.29c29.57-9.41 59.98-9.45 89.55 0 3.69 40.82-9.83 61.86-43.35 63.61-35.1-0.35-50.09-21.32-46.2-63.61m-13.48 81.86c-0.98-7.66 3.73-8.09 6.1-7.57 64.37 20.51-0.01 64.61-6.1 7.57m131.44-4.05c0-26.73-10.62-79.71-15.74-87.1-2.02-2.92-11.36-6.44-21.94-9.65-23.14-7.01-47.85-7.01-70.99 0-10.59 3.21-19.93 6.74-21.94 9.65-5.12 7.39-15.6 60.37-15.6 87.1s2.52 56.69 10.48 66.36c1.03 1.25 3.81 2.33 7.79 3.26v-1.82h12.19v3.76c23.54 2.7 61.82 2.7 85.3-0.02v-3.74h12.19v1.79c3.92-0.92 6.63-2 7.66-3.23 6.78-8.24 9.69-31.19 10.41-54.33 0.13-4.01 0.21-8.02 0.21-11.97v-0.06z"
                  fill="#fff"/>
            <path className="st0" d="m174.87 299.82v30.18h12.19v-30.18z" fill="#fff"/>
            <path className="st0" d="m272.35 299.82v30.18h12.19v-30.18z" fill="#fff"/>
        </g>
    );
}

export default AvantiStopsDot;
