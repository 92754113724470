import React, { useLayoutEffect, useState, useEffect, useCallback, useMemo, useRef, memo, useContext } from 'react';
import ReactMapGL, { GeolocateControl, NavigationControl, AttributionControl } from "react-map-gl";

import TrainMarkers from 'components/TrainMarkers'
import { calculateIconSize } from "utils/utils";
import ModalNavigation from 'components/ModalNavigation'
import './MapContainer.css';
import useMapParams from 'hooks/useMapParams';
import useFetchLocos from 'hooks/useFetchLocos';
import useFilterLocos from 'hooks/useFilterLocos';
import useDisplayLocos from 'hooks/useDisplayLocos';
import useTrackTrains from 'hooks/useTrackTrains'
import useSearchTrains from 'hooks/useSearchTrains'
import useModal from 'hooks/useModal'
import Modal from 'components/Modal/Modal';
import classnames from 'classnames'
import Route from 'components/Route';
import LanguageToggle from 'components/LanguageToggle';
import { useTranslation } from 'react-i18next'
import fetchTheme from "themes/fetchTheme"
import useMapParamsOverride from 'hooks/useMapParamsOveride';
import i18next from 'i18next';
import TrainIconLayer from 'components/TrainIconLayer/TrainIconLayer';
import ThemeContext from 'Contexts.js'
import useToggleLayers from "hooks/useToggleLayers";


function MapContainer() {

    const { theme, setTheme } = useContext(ThemeContext)
    const mapRef = useRef(null);
    const { t, i18n } = useTranslation();


    // Map parameters (these are the map paramaters that are stored in the URL)
    const mapParams = useMapParams()
    useMapParamsOverride(mapParams.setThemeName)

    const [iconSize, setIconSize] = useState(32);
  
    const [viewState, setViewState] = useState(theme.viewportDefaults)

    useLayoutEffect(()=>{
        if(mapParams.view!== undefined){
            setViewState(mapParams.view)
        }
    }, [mapParams.view])



    useEffect(() => {
        i18next.changeLanguage(mapParams.lang)
    }, [mapParams.lang])

    // This is the code that finds the trains in a given query. 
    const {
        searchTrains,
        searchRids,
        isError: searchError,
        isLoading: searchLoading
    } = useSearchTrains(mapParams.toc, mapParams.from, mapParams.to, mapParams.area);


    /*
    This is the code that fetches, filters and interpolates the trains that are displayed. 
    */
    const { isLoading, isError, locos } = useFetchLocos()
    const filteredLocos = useFilterLocos(locos, mapParams.currentTrain, searchRids, undefined) /// if filterRids is defined then trains will be filtered. 
    let displayLocos = useDisplayLocos(filteredLocos)

    const currentLoco = useMemo(() => locos.find(loco => loco.rid == mapParams.currentTrain)
        , [mapParams.currentTrain, locos])

    /*
    * This computes the viewport of the trains or trains being tracked. (If track rids is set to undefined then tracking is off.) 
    */
    const { tracking,
        setTracking,
        setTrackRids,
        trackError,
        trackBounds
    } = useTrackTrains(locos, setViewState)

    /**
     * This code controls the map modal. 
     */
    const {
        modalContent,
        setModalContent,
        setShowModal,
        showModal,
        MODAL_CONTENT
    } = useModal()

    // themes
    useEffect(() => {
        setTheme(fetchTheme(mapParams.themeName))

    }, [mapParams.themeName])

    
    /**
     * Train Marker Layer, on click event
     */
    const onMapClick = (e) => {
        setShowModal(false);
        setModalContent(MODAL_CONTENT.NONE)
        mapParams.setCurrentTrain(undefined)
        if (e.features.length === 0) return
        const marker = e.features.find((f) => f.layer.id === "train_icon")
        if (marker) {
            mapParams.setCurrentTrain(marker.properties.rid)
            setModalContent(MODAL_CONTENT.STOPS);
            setShowModal(true)
        }

    }
    const onMouseEnter = () => {
        const map = mapRef.current.getMap()
        map.getCanvas().style.cursor = 'pointer';
    }
    const onMouseLeave = () => {
        const map = mapRef.current.getMap()
        map.getCanvas().style.cursor = '';
    }
    const onTrainMarkerClick = useCallback((e, rid) => {
        e.originalEvent.stopPropagation();
        mapParams.setCurrentTrain(rid)
        setModalContent(MODAL_CONTENT.STOPS);
        setShowModal(true)
    }, [])

    const onCloseButton = useCallback(() => {
        setShowModal(false)
        mapParams.setCurrentTrain(undefined)
    }, [])

    useEffect(() => {
        if (mapParams.currentTrain !== undefined) {
            setTrackRids(mapParams.currentTrain);
        }

    }, [mapParams.currentTrain])

    const onInteraction = () => {
        setTracking(false)
    }

    useLayoutEffect(() => {
        setTracking(true);
        setTrackRids(searchRids);
    }, [searchRids]);


    const viewStateChange = (viewState) => {
        setViewState(viewState);
        setIconSize(calculateIconSize(viewState));
        mapParams.setView({
            latitude: viewState.latitude,
            longitude: viewState.longitude,
            zoom: viewState.zoom,
        })
    }

    const onMapLoad = useCallback(()  => {
        const map = mapRef.current.getMap()
        //turn off option to rotate map with SHIFT+Cursor
        map.keyboard.disableRotation()
        map.touchZoomRotate.disableRotation()
    }, []);

    useToggleLayers(mapRef?.current?.getMap()) 

    if (isLoading) {
        return (
            <div className={classnames("MapContainer", { "chrome": mapParams.chrome == "hide" })}>
                <h2 className='center'>{t('loading')}</h2>
            </div>
        )
    }

    if (isError) { // fetch error
        return (
            <div className="MapContainer">
                <h2 className='center'> Error fetching trains</h2>
            </div>
        )
    }

    if (trackError) { // fetch error
        return (
            <div className="MapContainer">
                <div className="center">
                    <h2> This train is not in service </h2>
                    <button className='button-light' onClick={() => { setTracking(false); mapParams.setCurrentTrain(undefined) }}> View Live Map</button>
                </div>
            </div>
        )
    }

    return (
        <div className={classnames("MapContainer", { "border": mapParams.chrome !== "hide" })}>

            <ReactMapGL className="mapbox-map"
                {...viewState}
                initialViewState={theme.viewportDefaults}
                width='100%'
                height='100%'
                mapStyle={theme?.mapboxStyle}
                mapboxAccessToken={"pk.eyJ1Ijoic2lnbmFsYm94IiwiYSI6ImNpeThza29oMjAwMTkyd3AzazYweGgxazgifQ.f7SV743q_ULYFNXeW7mc7g"}
                onMove={evt => viewStateChange(evt.viewState)}
                onLoad={onMapLoad}
                ref={map => mapRef.current = map}
                mapOptions={{ logoPosition: 'bottom-left' }}
                onMouseDown={onInteraction}
                onTouchStart={onInteraction}
                onWheel={onInteraction}
                interactiveLayerIds={['train_icon']}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={onMapClick}
                attributionControl={false} // disable the default attribution
            >

                <AttributionControl customAttribution="&copy Trainline" style={{ bottom: 0, left: 0 }} />

                {theme.name === 'trainline' || theme.name === 'default' ?

                    <TrainIconLayer
                        currentTrains={displayLocos}
                        zoom={viewState?.zoom}
                        currentTrain={mapParams.currentTrain}
                        Icon={theme?.TrainLayerIcon}
                    />
                    :
                    <TrainMarkers
                        currentTrains={displayLocos}
                        iconSize={iconSize}
                        currentTrain={mapParams.currentTrain}
                        onTrainMarkerClick={onTrainMarkerClick}
                        coloring={mapParams.coloring}
                        TrainIcon={theme?.TrainIcon}
                    />

                }

                <Route
                    currentTrain={mapParams.currentTrain}
                    delay={currentLoco?.delay}
                    toc={currentLoco?.toc_code}
                    themeToc={theme?.toc}
                />

                {mapParams.chrome != "hide" ?

                    <>
                        <NavigationControl style={theme?.navigationControlStyle} />

                        <GeolocateControl
                            style={theme?.geolocateControlStyle}
                            positionOptions={{ enableHighAccuracy: true }}
                            trackUserLocation={true}
                        />

                        {theme?.BetaTag && <theme.BetaTag />}

                        {theme?.name === 'tfw' && <LanguageToggle
                            setLang={mapParams.setLang}
                            lang={mapParams.lang} />}

                        <ModalNavigation
                            setModalContent={setModalContent}
                            setShowModal={setShowModal}
                            searchApplied={searchRids !== undefined}
                            modalContent={modalContent}
                            showModal={showModal}
                            PrimaryLogo={theme?.Logo}
                            HomeWidget={theme?.HomeWidget}
                        >

                            <Modal
                                showModal={showModal}
                                modalContent={modalContent}
                                setModalContent={setModalContent}
                                setShowModal={setShowModal}
                                onCloseButton={onCloseButton}

                                toc={mapParams.toc}
                                setToc={mapParams.setToc}
                                from={mapParams.from}
                                setFrom={mapParams.setFrom}
                                to={mapParams.to}
                                setTo={mapParams.setTo}
                                area={mapParams.area}
                                setArea={mapParams.setArea}
                                currentTrain={mapParams.currentTrain}
                                setCurrentTrain={mapParams.setCurrentTrain}
                                searchApplied={searchRids !== undefined}

                                searchTrains={searchTrains}
                                searchError={searchError}
                                searchLoading={searchLoading}

                                tracking={tracking}
                                setTracking={setTracking}
                                setTrackRids={setTrackRids}

                                CustomDot={theme.StopsDot}

                                CustomKey={theme.Key}

                                customNoTrains={theme && theme.NoTrains ? < theme.NoTrains /> : undefined}
                            />
                        </ModalNavigation>

                    </>
                    : null}

            </ReactMapGL>
        </div>
    );

}

export default memo(MapContainer);





