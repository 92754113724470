import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import InfoButton from 'components/ModalNavigation/InfoButton/InfoButton';
import CloseButton from 'components/ModalNavigation/CloseButton';
import SearchButton from 'components/ModalNavigation/SearchButton'
import FindMyTrainButton from 'components/ModalNavigation/FindMyTrainButton'
import classnames from 'classnames';
import SignalboxLogo from 'components/ModalNavigation/SignalboxLogo'
import useViewport from './useViewport';

import './ModalNavigation.css';
import useModal from 'hooks/useModal';
import DefaultHomeWidget from 'components/HomeWidget';
import ThemeContext from 'Contexts.js'



function ModalNavigation(props) {
    
  const { 
      modalContent,
      showModal,
      setModalContent,
      setShowModal,
      searchApplied,
      PrimaryLogo,
      HomeWidget = DefaultHomeWidget,
        } = props;

    const { width } = useViewport();
    const breakpoint = 620;

    const {theme, setTheme} = useContext(ThemeContext)

    const {MODAL_CONTENT} = useModal()

    const onSearchClicked = function(){
      setModalContent(MODAL_CONTENT.SEARCH)
      setShowModal(true)
    }
    const onInfoClicked = function(){
      setModalContent(MODAL_CONTENT.INFO)
      setShowModal(true)
    }
    const onFindMyTrainClicked = function(){
      setModalContent(MODAL_CONTENT.FIND_MY_TRAIN)
      setShowModal(true)
    }

    const onLogoClick = ()=>{
      setModalContent(MODAL_CONTENT.HOME)
      setShowModal(true);
    }

    if (MODAL_CONTENT.HOME == modalContent && showModal){
      return (

        <div className={classnames('ModalNavigation',  'home', {'open': showModal})}>

           <HomeWidget setModalContent={setModalContent} setShowModal={setShowModal}  themeName={theme.name}/>
                 
        </div>

      )
    }

    return (
        <>  
            
            <div className={classnames('ModalNavigation', modalContent , {'open': showModal})}>

              {
                <div className={classnames('header', {'open': showModal})}>
           
                  <div className='button-wrapper'>
                  <div className='logo-wrapper' onClick={onLogoClick}>
                    {PrimaryLogo === undefined?
                      <SignalboxLogo className='primary-logo'/>:  <PrimaryLogo/>
                          }
              
                  </div>
                      <SearchButton onClick={onSearchClicked} 
                      applied={searchApplied} className='menu-button'
                      selected={MODAL_CONTENT.SEARCH == modalContent && showModal}
                      />
                      <FindMyTrainButton onClick={onFindMyTrainClicked} 
                      className='menu-button'
                      selected={MODAL_CONTENT.FIND_MY_TRAIN == modalContent && showModal}/>
                      <InfoButton onClick={onInfoClicked} 
                      className='menu-button'
                      selected={MODAL_CONTENT.INFO == modalContent && showModal}/>
                      {showModal&&
                          <CloseButton onClick={()=>setShowModal(false)}/>
                      }
                  </div>
                </div>
                }

                {(showModal) &&
                (
                  <div className='modal-body'>
                      {props.children}
                    </div>           
                )
                }
              </div>
    
        </>
        
    );
    
}

ModalNavigation.propTypes = {
    currentTrain: PropTypes.string,
    setShowModal: PropTypes.func,
    setModalContent: PropTypes.func,
    HomeWidgetCustom: PropTypes.object
};

export default ModalNavigation;
