
import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import './SearchResult.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Trains from 'components/Trains'
import Train from 'components/Train'
import { useTranslation } from 'react-i18next'


function SearchResult({
  searchTrains,
  onSelectTrain,
  trainOperator,
  fromStation,
  toStation,
  area,
  setTracking
}) {

  const { t, i18n } = useTranslation();

  const [viewTrains, setViewTrains] = useState(false);

  const onClickViewTrains = function () {
    setViewTrains(!viewTrains);
  }

  const delayTrains = searchTrains?.filter((train) => { return train.delay > 1 })

  
  return (
    <div className="SearchResult">

      <h1 className='padding-large'>  {searchTrains?.length === 0 ? <> {t('search_widget.no_trains_found')} </> :
            <> {searchTrains?.length} {t('search_widget.results')} </>
          }</h1>

      <div className="label color-primary"> {t('search_widget.applied_filters')} </div>
          <h2 >
            {area && <> area <br /> </>}
            {trainOperator && <> {trainOperator} <br /> </>}
            {fromStation && <> from {fromStation}  <br /></>}
            {toStation && <> to {toStation}  <br /></>}
        </h2>
      <div className='padding-small'></div>
   
      {viewTrains &&

        searchTrains?.length === 0 ? (
        <div className='no-trains'>
          No trains found
        </div>
      ) :

        (<Trains trains={searchTrains} 
           onSelectTrain={onSelectTrain}
           setTracking={setTracking} />)
      }
      <div>

        <p>
        </p>
        <p>
        {t('search_widget.info_text')}
        </p>


      </div>

    </div>
  )
}


SearchResult.propTypes = {
  filterTrains: PropTypes.any,
}

export default memo(SearchResult)
