// This animates the display locations by interpolating between points. 
// The refresh rate is determined by the number of trains that need to animated (the less trains the faster the refresh rate).  

import { useState, useEffect } from 'react';
import turf from "turf";
import { round } from "@turf/helpers";


function useDisplayLocos(inputLocos) {

    const [displayLocos, setDisplayLocos] = useState([])
    const [rerun, setRerun] = useState(100)
    // Rerun specifies the time in miliseconds between rerenders (dependent on number of trains)

    // Upsample train locations
    useEffect(() => {

        const interpolateLocation = function (tl) {
            //Heading and interpolated location

            let start_ts = new Date(tl["ts"]);
            let predicted_ts = new Date(tl["predicted_ts"]);
            
            let heading = Math.ceil(turf.bearing(turf.point([tl.location.lon, tl.location.lat]), turf.point([tl.predicted_location.lon, tl.predicted_location.lat])) / 10) * 10;

            let now = new Date();

            let lat; let lon;

            if (now > predicted_ts){
                lon = tl.predicted_location.lon;
                lat = tl.predicted_location.lat;
            }else{
                let fol = (now - start_ts) / (predicted_ts - start_ts);
                lon = round(tl.location.lon + (tl.predicted_location.lon - tl.location.lon) * fol, 5);
                lat = round(tl.location.lat + (tl.predicted_location.lat - tl.location.lat) * fol, 5);
            }

            return {
                rid: tl.rid,
                delay: tl.delay,
                lat: lat,
                lon: lon,
                heading: heading,
                tocCode: tl.toc_code,
            }
        }

        const fastTimeout = setTimeout(() => {
            if (inputLocos == undefined) {
                return
            }
            const interpolatedLoc = inputLocos.map((loc) => {
                return interpolateLocation(loc)

            })
            setDisplayLocos(interpolatedLoc)
            setRerun(1 * inputLocos.length + 100)

        }, rerun);

        return () => clearTimeout(fastTimeout) // cleanup function

    }, [inputLocos, displayLocos])

    return displayLocos;
}

export default useDisplayLocos;

