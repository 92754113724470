import React from 'react';
import PropTypes from 'prop-types';

import './AvantiTrainIcon.css';

import avantiGreen from './assets/avanti-green.svg';
import avantiAmber from './assets/avanti-amber.svg';
import avantiRed from './assets/avanti-red.svg';
import avantiSelectedGreen from './assets/avanti-selected-green.svg';
import avantiSelectedAmber from './assets/avanti-selected-amber.svg';
import avantiSelectedRed from './assets/avanti-selected-red.svg';
import otherGreen from './assets/other-green.svg';
import otherAmber from './assets/other-amber.svg';
import otherRed from './assets/other-red.svg';
import otherSelectedGreen from './assets/other-selected-green.svg';
import otherSelectedAmber from './assets/other-selected-amber.svg';
import otherSelectedRed from './assets/other-selected-red.svg';
import { Opacity } from '@mui/icons-material';

function getSvg(delay, tocCode, isFocused) {
    if (tocCode === "VT") {
        if (isFocused) {
            if (delay === null || delay === undefined) {
                return avantiSelectedGreen;
            }

            if (delay > 0 && delay < 10) {
                return avantiSelectedAmber;
            }

            if (delay >= 10) {
                return avantiSelectedRed;
            }

            return avantiSelectedGreen;
        }

        if (delay === null || delay === undefined) {
            return avantiGreen;
        }

        if (delay > 0 && delay < 10) {
            return avantiAmber;
        }

        if (delay >= 10) {
            return avantiRed;
        }

        return avantiGreen;
    }

    if (isFocused) {
        if (delay === null || delay === undefined) {
            return otherSelectedGreen;
        }

        if (delay > 0 && delay < 10) {
            return otherSelectedAmber;
        }

        if (delay >= 10) {
            return otherSelectedRed;
        }

        return otherSelectedGreen;
    }

    if (delay === null || delay === undefined) {
        return otherGreen;
    }

    if (delay > 0 && delay < 10) {
        return otherAmber;
    }

    if (delay >= 10) {
        return otherRed;
    }

    return otherGreen;
}

function AvantiTrainIcon(
    {
        delay = 0,
        isFocused = false,
        size = 64,
        heading = 0,
        tocCode = undefined,
    }
) {
    const factor = 1.7 // increase size of all Avanti icons by this amount
    let iconSize = tocCode === "VT" ? factor * size : factor * size * 0.7;
    let opacity = tocCode === "VT" ? 1: 1; // fade non-avanti trains
    let iconStyle = {
        height: iconSize,
        width: iconSize,
        opacity: opacity
    };

    if (isFocused) {
        iconStyle.transform = 'scale(1.2, 1.2)';
    }

    const svgStyle = {
        transform: `rotate(${heading}deg)`
    };

    return (
        <div
            className="AvantiTrainIcon"
            style={iconStyle}
        >
            <img
                src={getSvg(delay, tocCode, isFocused)}
                style={svgStyle}
                alt={""}
            />
        </div>
    );
}

AvantiTrainIcon.propTypes = {
    delay: PropTypes.number,
    isFocused: PropTypes.bool,
    size: PropTypes.number,
    heading: PropTypes.number,
    tocCode: PropTypes.string,
};

export default React.memo(AvantiTrainIcon);
