// This class is used to show a list of trains (from either the filter results or the detect results) 

import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import './TrainSummary.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import RecentreButton from 'components/TrainSummary/RecentreButton';
import classnames from 'classnames';
import {DateTime} from "luxon";
import { useTranslation } from 'react-i18next'

function TrainSummary(props) {


  const {
    train,
    onSelectTrain,
    setTracking,
    tracking,
    recentreEnabled = true,
    onShareClick = ()=>{}
  } = props

  const { t, i18n } = useTranslation();

  
  if (!train || train === undefined) {

    return (
      <div className="TrainSummary">
        <div>{t("loading")}</div>
      </div>
    )
  }

  const selectEnabled = onSelectTrain !== undefined; 

  const onSelectClick = function(rid){
    if(selectEnabled){
      setTracking(true)
      onSelectTrain(rid)
    }
  }

  const onRecentreClick = function(rid){
    if(setTracking!==undefined){
      setTracking(true)
    }
  }


  const displayTime = function (timeString) {
    return DateTime.fromISO(timeString).toFormat("HH:mm") 
  }

 // if select is enabled then you can select the train

  //Hack to show Transport for Wales in Welsh for Welsh language version. 
  let train_operator = train.train_operator
  if(train.train_operator == 'Transport for Wales' && i18n.language=='cy'){
    train_operator = 'Trafnidiaeth Cymru'
  }

  return (
    <div className={classnames("TrainSummary", {'select-enabled': selectEnabled})} key={train.rid}  onClick={() => {onSelectClick(train.rid);}} >
      <div className='wrapper'>
        <div className="toc-name">{train_operator}</div>
        <h2 className="train-name">{train.origin_name} <div className='display-time'> {displayTime(train.origin_departure)}</div> <br></br>
          {t('to')} {train.destination_name} <div className='display-time'> {displayTime(train.destination_arrival)} </div> </h2>
        <div className="train-delay"> 
          {train.delay < 1? t('on_time'): `${t('delayed')} ${train.delay} min`}
        </div>
        { recentreEnabled && 
        <div className="recentre-wrapper">
          <span><RecentreButton onClick={() => {onRecentreClick()} } disabled={tracking}/></span>
          <span className='headcode'>{train.headcode}</span>
         </div> 
        }
        { recentreEnabled && 
          <div className="recentre-wrapper">
            <button className='share-button' onClick={() => {onShareClick()} } >Share arrival time </button>
          {/* <span><ShareButton onClick={() => {onShareClick()} }/></span> */}
         </div> 
        }
        
      </div>
    </div>

  )
}



TrainSummary.propTypes = {
  trains: PropTypes.any,
}



export default memo(TrainSummary)
