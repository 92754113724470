import React, { memo } from 'react'
import PropTypes from 'prop-types'
import ReactSelect, { createFilter } from 'react-select'
import classnames from 'classnames';
import useTrainOperatorSelector from './useTrainOperatorSelector'
import useStationSelector from './useStationSelector'
import './SearchForm.css';
import turf from "turf";
import { round } from "@turf/helpers";
import { useTranslation } from 'react-i18next'


function SearchForm({
  trainOperators,
  stations,
  selectedToc,
  setSelectedToc,
  selectedFromStation,
  setSelectedFromStation,
  selectedToStation,
  setSelectedToStation,
  selectedArea,
  handleApplySearch,
  handleReset,
  className
}) {

  const { t, i18n } = useTranslation();

  const onOperatorChange = function (selected) {
    // alert(selected)
    if (selected == null) {
      setSelectedToc(undefined)
    } else {
      setSelectedToc(selected)
    }
  }

  const onFromStationChange = function (selected) {
    if (selected == null) {
      setSelectedFromStation(undefined)
    } else {
      setSelectedFromStation(selected)
    }
  }

  const onToStationChange = function (selected) {
    if (selected == null) {
      setSelectedToStation(undefined)
    } else {
      setSelectedToStation(selected)
    }
  }

  const { optionsReducer: operatorOptionsReducer } = useTrainOperatorSelector()
  const { optionsReducer: stationOptionsReducer } = useStationSelector()


  return (
    <div className="SearchForm">

      <div className='body'>
          <h1> {t('search_widget.search_trains')} </h1>
          
          <div className='form-container'>
            <div className='label'> {t('search_widget.train_company')}</div>
            <ReactSelect
              className={classnames('autocomplete', className)}
              classNamePrefix="autocomplete"
              options={operatorOptionsReducer(trainOperators)}
              onChange={onOperatorChange}
              placeholder={t('search_widget.placeholder')}
              isClearable={true}
              value={selectedToc}
            >
            </ReactSelect>
          </div>

          <div className='form-container'>
            <div className='label'> {t('search_widget.from')} </div>
            <ReactSelect
              className={classnames('autocomplete', className)}
              classNamePrefix="autocomplete"
              options={stationOptionsReducer(stations)}
              onChange={onFromStationChange}
              filterOption={createFilter({ ignoreAccents: false })}
              placeholder={t('search_widget.placeholder')}
              value={selectedFromStation}
              isClearable={true}
            >
            </ReactSelect>
          </div>

          <div className='form-container'>
            <div className='label'> {t('search_widget.to')} </div>
            <ReactSelect
              className={classnames('autocomplete', className)}
              classNamePrefix="autocomplete"
              options={stationOptionsReducer(stations)}
              onChange={onToStationChange}
              filterOption={createFilter({ ignoreAccents: false })}
              placeholder={t('search_widget.placeholder')}
              value={selectedToStation}
              isClearable={true}
            >
            </ReactSelect>
          </div>

        <div className="footer">
              <button
                className="button-dark"
                disabled={!(selectedToc || selectedFromStation
                  || selectedToStation || selectedArea)}
                type="button"
                onClick={handleApplySearch}>
                 {t('search_widget.search')}
              </button>
              <div className="padding-medium">
                <div className="reset" onClick={handleReset}> {t('search_widget.reset')} </div>
              </div>
        </div>

      </div>   

    </div>

  )

}

SearchForm.propTypes = {
  filterTrains: PropTypes.any,
}

export default memo(SearchForm)
