// This fetches train locations (aka "locos", which is the abbreviation we use
// for train locations objects)

import { useEffect, useState } from 'react';
import axios from 'axios'
import { useQuery } from 'react-query'
import {apiBaseUrl} from "../utils/utils";


function useFetchLocos() {


    const base_url = apiBaseUrl();
    const [locos, setLocos] = useState([])

    //TODO fetch interval needs to be modified
    const { isLoading, isError, data, error } = useQuery('locations', () => { return axios.get(`${base_url}/api/locations`) },
        {
            refetchInterval: 20000
        })

    useEffect(() => {
        if (data) {
            setLocos(data.data.train_locations)
            //console.log('fetch locos')
        }
    }, [data])

    return { isLoading, isError, locos }

}

export default useFetchLocos
