import i18n from 'i18next';
import { initReactI18next} from 'react-i18next'


i18n
  .use(initReactI18next)
  .init({
  debug: true,
  fallbackLng: 'en',
  resources: {
    en: {
      translation: {
        "loading": "Loading...",
        "on_time": "On time"  ,
        "delayed": "Delayed",
        "to": "to",
        "home": {
          'search_trains_label': 'Search for live trains',
          'search_trains': 'Search trains',
          'find_my_train_label': "Find the train you are on",
          'find_my_train': "Find my train",
          'train_map_label': "View live train locations",
          'train_map': "Train map",
          'powered_by': "Powered by"
        },
        "key": {
          "key_title": "Key",
          "tfw_trains": "Transport for Wales trains",
          "green_text": "On time",
          "amber_text": "1-10 mins delay",
          "red_text": "10+ mins delay",
          "other_trains": "Other trains",
          "delay_not_shown": "Service status not shown",
          "info_text": `For a moving train, the arrow indicates the direction of travel. 
                  When a train is stationary the marker arrow points north.`
        },
        "info": {
          "about_title": "About",
          "about_text": `This app uses Signalbox API, which combines real-time transport information with
                        sophisticated GPS and mapping technology to geolocate devices and match them with 
                        trains on the rail network in Great Britain.`,
          "feedback_title": "Feedback",
          "feedback_text": `To report bugs, issues and to provide feedback and suggestions, please send 
                            an email to info.signalbox@thetrainline.com. `,
          "cookies_title": "Cookies and privacy",
          "cookies_text": `This app uses cookies to allow us to remember that you have visited us before. 
                          This allows us to improve the quality of our train estimates. Although we 
                          use cookies to identify a user's device, we do not use cookies to store any 
                          personal information, such as name or email address.
                          If you continue using this app, we will assume that you are happy for cookies to be
                          used in the manner described.`,  
          "licensing_title": "Licensing information",
          "licensing_text": `Contains Information of Network Rail Infrastructure Limited licensed
                            under the following licence.
                            Powered by National Rail Enquiries.`,
          "troubleshooting_title": "Troubleshooting",
          "troubleshooting_text": `If trains are not displaying or updating, try refreshing the page. Also, 
                                      make sure you have a good internet connection. For a "Location Unavailable" response, 
                                      try updating your browser to the most recent version or, alternatively, try 
                                      viewing on a newer device. In addition, in the settings of your browser, make sure
                                      JavaScript is enabled and geolocation permission is given.`,
          "safety_title": "Safety",
          "safety_text": `Not to be used for safety critical applications. The information presented here is 
                          not guaranteed to be correct. In addition, note that the map shows only passenger 
                          trains and that many other types of trains (like freight trains) are present in 
                          large numbers on the network.`
        },

        "find_my_train":{
          "find_my_train":"Find my train",
          "label": "Automatically find the train you are on",
          "searching": "Searching...", 
          "trains_found": "Trains found",
          "no_train": "No train discovered",
          "no_train_label": "Please check your location and connection settings",
          "try_again": "Try again",
          "back": "Back"
         },
         "train":{
           "track_and_share": "Track and share your train",
           "track_and_share_label": "Share your live journey",
           "select_your_destination": "Select your destination",
           "destination": "Destination",
           "enter_your_station": "Enter your station",
           "confirm_station": "Confirm station",
           "share_my_journey": "Share my journey",
           "results": "Results",
           "select_station": "Select station",
           "platform": "Platform",
           "train_journey": "Train journey"
         },
         "eta_sharing": {
          'share_my_journey': "Share my journey",
          'share_tray_not_supported': 'Share tray not supported on this browser. Web page link has been copied for sharing manually',
          'arriving_at': 'Arriving at',
          'in': 'in',
          'minutes': 'minutes',
          'just_arrived_at': 'Just arrived at',
          'ago': 'ago'
         },
         "recentre": {
             "locate": "Locate train",
             "tracking": "Tracking on"
         },
         "search_widget": {
          'search_trains': 'Search trains',
          'train_company': "Train company",
          "from": "From",
          "to": "To",
          "placeholder": "All",
          "search": "Search",
          "reset": "Reset",
          "view_results": "View results",
          "edit": "Edit",
          "no_trains_found": "No trains found",
          "results": "results",
          "applied_filters": "Applied filters",
          "info_text": `Please note that replacement bus services aren't shown in search results.
          For journeys with a change, please enter each journey separately. Please visit the relevant train companies website for information on improvement works.` 
         },
        "eta": {
          "live_train_journey": "Live train journey"
        }
      }
    },

    cy: {
      translation: {
        "loading": "Llwytho...",
        "on_time": "Ar amser"  ,
        "delayed": "Wedi’i ddileu",
        "to": "i",
        "home": {
          'search_trains_label': 'Chwilio am drênau fyw',
          'search_trains': 'Chwilio am drênau',
          'find_my_train_label': "Dod o hyd i'r trên rydych chi arno",
          'find_my_train': "Dod o hyd i'm trên",
          'train_map_label': "Gweld lleoliadau trenau byw",
          'train_map': "Map trên",
          'powered_by': "Wedi ei bweru gan"
        },
        "key": {
          "key_title": "Allwedd Map",
          "tfw_trains": "Trenau Trafnidiaeth Cymru",
          "green_text": "Ar Amser",
          "amber_text": "Wedi’i ddileu 1 – 10 munud",
          "red_text": "Wedi’i ddileu 10+ munud",
          "other_trains": "Trenau eraill",
          "delay_not_shown": "Statws gwasanaeth ddim ar gael",
          "info_text": `Pan mae trên yn symyd, mae’r saeth yn dangos i ba 
                      gyfeiriad y mae’n teithio. Pan nad yw’r trên yn symud, 
                      bydd y saeth yn wynebu’r Gogledd.`
        },
        "info": {
          "about_title": "Amdanom",
          "about_text": `Mae'r ap hwn yn defnyddio Signalbox API, sy'n cyfuno gwybodaeth teithio amser 
                      real â GPS soffistigedig a thechnoleg mapio i geoleoliad dyfeisiau a'u paru â 
                      threnau ar rwydwaith rheilffyrdd Prydain Fawr.`,
          "feedback_title": "Adborth",
          "feedback_text": `I roi gwybod am fygiau, problemau ac i rannu adborth ac 
                            awgrymiadau, e-bostiwch info.signalbox@thetrainline.com.`,
          "cookies_title": "Cwcis a Preifatrwydd",
          "cookies_text": `Mae'r wefan hon yn defnyddio cwcis i'n helpu i gofio eich bod eisoes wedi ymweld â ni. 
                          Mae hyn yn ein galluogi i wella ansawdd ein hamcangyfrifon trenau. 
                          Er ein bod yn defnyddio cwcis i adnabod dyfais defnyddiwr, ni wnawn ei defnyddio 
                          i storio unrhyw fanylion personol, fel enw neu gyfeiriad e- bost.
                          Os byddwch yn parhau i ddefnyddio'r wefan hon, 
                          byddwn yn cymryd yn ganiataol eich bod yn hapus i ni ddefnyddio cwcis yn y modd â ddisgrifir.`, 
          "licensing_title": "Gwybodaeth Trwyddediad",
          "licensing_text": `Yn cynnwys gwybodaeth am Network Rail Infrastructure Limited a drwyddedwyd o dan y 
                            drwydded ganlynol. Fe'i gweithredir gan National Rail Enquiries.`,         
          "troubleshooting_title": "Cywiro diffygion",
          "troubleshooting_text": `Os nad yw manylion y trenau yn ymddangos neu'n diweddaru, adnewyddwch y dudalen. 
                                  Hefyd, sicrhewch bod gennych gysylltiad da â’r rhyngrwyd. Os cewch ymateb "Location Unavailable", 
                                  diweddarwch eich porwr i'r fersiwn ddiweddaraf neu, defnyddiwch ddyfais mwy 
                                  diweddar i chwilio. Yn ogystal, yng ngosodiadau eich porwr, sicrhewch bod JavaScript 
                                  wedi'i droi ymlaen yn ogystal â’r dewis 'geolocation`,
          "safety_title": "Diogelwch",
          "safety_text": `Ni ddylid ei ddefnyddio ar gyfer ceisiadau lle mae diogelwch yn gritigol. Ni ellir gwarantu bod 
                          yr wybodaeth a rennir yma yn gywir. Yn ogystal, dyw'r map ond dangos trenau teithwyr ac mae 
                          llawer iawn o fathau eraill o drenau i'w gweld ar y rhwydwaith (fel trenau cludo nwyddau).`
        },
        "find_my_train":{
          "find_my_train":"Chwilio am drên",
          "label": "Dewch o hyd i'r trên rydych chi arno'n awtomatig",
          "searching": "Chwilio...", 
          "trains_found": "Trenau",
          "no_train": "Methu dod o hyd i drên",
          "no_train_label": `Gwiriwch eich gosodiadau lleoliad a cysylltiadau.`,
          "try_again": "Ceisio eto",
          "back": "Yn ol"
         },
         "train":{
          "track_and_share": "Olrhain a rhannu eich trên.",
          "track_and_share_label": "Rhannwch eich taith fyw",
          "select_your_destination": "Dewiswch eich cyrchfan",
          "destination": "Cyrchfan",
          "enter_your_station": "Nodwch eich gorsaf",
          "confirm_station": "Cadarnhau gorsaf",
          "share_my_journey": "Rhannu fy nhaith",
          "results": "Canlyniadau",
          "select_station": "Dewiswch orsaf",
          "platform": 'Platfform',
          "train_journey": "Taith trên"
        },
        "eta_sharing": {
          'share_my_journey': "Rhannu fy nhaith",
          'share_tray_not_supported': `Ni chefnogir hambwrdd rhannu ar y porwr hwn. 
                                  Mae dolen tudalen we wedi'i chopïo i'w rhannu â llaw`,
          'arriving_at': 'Cyrraedd gorsaf',
          'in': 'mewn',
          'minutes': 'munud',
          'just_arrived_at': 'Newydd gyrraedd',
          'ago': 'yn ôl'
              },
            "recentre": {
                "locate": `Lleoli trên`,
                "tracking": "Tracio ymlaen"
            },
            "search_widget": {
              'search_trains': 'Chwilio am drenau',
              'train_company': "Cwmni trên",
              "from": "O",
              "to": "I",
              "placeholder": "Holl",
              "search": "Chwilio",
              "reset": "Ailosod",
              "view_results": "Gweld canlyniadau",
              "edit": "Golygu",
              "no_trains_found": "Methu dod o hyd i drên",
              "results": "canlyniadau",
              "applied_filters": "Hidlyddion cymhwysol",
              "info_text": `Pan mae trên yn symyd, mae’r saeth yn dangos i ba 
              gyfeiriad y mae’n teithio. Pan nad yw’r trên yn symud, 
              bydd y saeth yn wynebu’r Gogledd.`
             },
             "eta": {
              "live_train_journey": "Taith trên fyw"
            }
      }
    }
  }
});

export default i18n;