import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './InfoButton.css';
import { ReactComponent as InfoSquare } from "./info_square.svg";

function InfoButton(props) {


    const { onClick, className, selected } = props;

    const selectedClass = selected ? "selected" : "";

    return (
        <div className={'InfoButton'} onClick={onClick}>
            <div className={classnames('button-small', { "selected": selected })}>
                <InfoSquare />
            </div>
        </div>
    );
}

InfoButton.propTypes = {
    onClick: PropTypes.func,
};

export default InfoButton;
