/* This class handles processing the geolocation and preparing the request to send to Signalbox.
This class is a singleton (i.e. only one singleton instance can be created)
*/

export default class SnapshotLocation {

    methodId = {
        FIRST_LOCATION: 1,
        FIXED_TIME: 2,
        LOCATION_MONITORING: 3
    };

    instance = null;

    constructor() {

        this.self = this;

        this.lastLocation = null; // Object containing most recent location
        this.maxTime = 6000; //The maximum time (ms) before returning a location (even if the location didn't satisfy locationCriteria)
        this.watchId = null;  // geolocation watch id.
        this.options = {
            enableHighAccuracy: true, //location accuracy (for most applications, this should be set high accuracy).
            maximumAge: 1000, // the maximum age (in milliseconds) allowed to use an old location (rather than try and get a new one).
            timeout: 15000  // The timeout attribute denotes the maximum length of time (ms) that is allowed to pass from the first call
        };
        this.method = this.methodId.LOCATION_MONITORING; // toggles location monitoring
        this.locationCriteria = {
            minimumAccuracy: 55,
            bearingRequired: true, //holds out for bearing if set to true
            speedRequired: true  //holds out for s[eed
        };
        this.timeoutId = null; // this is the timer method.

    }

    static init(){
        return new SnapshotLocation();
    }

    static getInstance(){
        if ( !this.instance ) {
            this.instance = this.init();
          }
        return this.instance
    }

    onSuccess(position) {
        console.log("On success default");
    }; //callback function for processing location response.

    onError(error) {

        switch (error.code) {
            case error.PERMISSION_DENIED:
                console.log("User denied the request for Geolocation.");
                break;
            case error.POSITION_UNAVAILABLE:
                console.log("location information is unavailable.");
                break;
            case error.TIMEOUT:
                console.log("The request to get user location timed out.");
                break;
            case error.UNKNOWN_ERROR:
                console.log("An unknown error occurred.");
                break;
        }
    };

    stop() {
        console.log("Stopped trying to find location");
        if (this.timeoutId !== null) {
            clearTimeout(this.timeoutId);
        }
        if (this.watchId !== null) {
            console.log('Watch id ' + String(this.watchId))
            navigator.geolocation.clearWatch(this.watchId);
        }
    };

    acquireLocation(onSuccess = null, onError = null, method = this.methodId.FIXED_TIME) {

        if (onSuccess !== null) {
            this.onSuccess = onSuccess;
        }//onSuccess callback.
        if (onError !== null) {
            this.onError = onError;
        }
        this.method = method;

        let self = this;
        this.timeoutId = setTimeout(this.finalProcess.bind(self), this.maxTime);

        let geolocationSupported = ("geolocation" in navigator); //check to see if geolocation is supported.

        if (geolocationSupported) {

            console.log('Geolocation is supported, watch position has started.');
            this.watchId = navigator.geolocation.watchPosition(
                function (position) {
                    self.processNewLocation.call(self, position)
                },
                function (error) {
                    self.onError.call(self, error)
                }, this.options);
        } else {
            console.log('Geolocation is not supported');
            // alert('Geolocation is not supported');
        }
    };

    processNewLocation(position) {

        console.log('processing new location');
        this.setLastLocation(position);
        if (this.checkLocationCriteria(position)) {
            this.finalProcess();
        } else {
        }
    };

    setLastLocation(position) {

        this.lastLocation = {
            coords: {
                accuracy: position.coords.accuracy,
                altitude: position.coords.altitude,
                altitudeAccuracy: position.coords.altitudeAccuracy,
                heading: position.coords.heading,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                speed: position.coords.speed
            },
            timestamp: position.timestamp
        };
        //console.log("Location received: ", JSON.stringify(this.lastLocation));
    };

    checkLocationCriteria(position) {

        if (this.method === this.methodId.FIRST_LOCATION) {
            return true
        }
        if (this.method === this.methodId.FIXED_TIME) {
            return false
        }
        // console.log("Checking location criteria");

        if (position.coords.accuracy > this.locationCriteria.minimumAccuracy) {
            return false
        }
        if (position.coords.heading === null && this.locationCriteria.bearingRequired) {
            return false
        }
        if (position.coords.speed === null && this.locationCriteria.speedRequired) {
            return false
        }
        return true
    };

    finalProcess() {

        // console.log("Final process location");

        // Clear watches and timeouts.
        window.navigator.geolocation.clearWatch(this.watchId);
        clearTimeout(this.timeoutId);

        if (this.lastLocation !== null) {
            this.onSuccess(this.lastLocation);
        } else {
            this.onError(1);
        }

    };


    static prettyHtml(position) {
        return "<br> accuracy: " + position.coords.accuracy +
            "<br> heading: " + position.coords.heading +
            "<br> speed: " + position.coords.speed +
            "<br> longitude: " + position.coords.longitude +
            "<br> latitude: " + position.coords.latitude;
    }

    /*
    * Parse position to format that can be sent to Signalbox API
     */
    static positionParse(position) {

        let pos = {};
        pos.lat = position.coords.latitude;
        pos.lon = position.coords.longitude;
        pos.accuracy = position.coords.accuracy;

        if (position.coords.altitude) {
            pos.altitude = position.coords.altitude;
        }

        if (position.coords.speed) {
            pos.speed = position.coords.speed;
        }
        if (position.coords.heading) {
            pos.bearing = position.coords.heading;
        }

        if (null != position.timestamp) {
            pos.ts = new Date(position.timestamp).toISOString();
        }
        return pos;
    };

};


