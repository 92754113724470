// This class is used to show a list of trains (from either the filter results or the detect results) 

import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import './Trains.css';

import TrainSummary from 'components/TrainSummary'


function Trains ({
  trains,
  onSelectTrain,
  setTracking
}) {


    return (
    
    <div className="Trains">

              {trains?.map(train => {
                      return (
                        <div className='train-wrapper' key={'wrapper-'+train.rid}>
                          <TrainSummary train={train} 
                          key={train.rid} 
                          onSelectTrain={onSelectTrain} 
                          setTracking={setTracking}
                          recentreEnabled={false}
                          />
                        </div>
                      );
                      })
              }
          
    </div>
  )
   
}

Trains.propTypes = {
  trains: PropTypes.any,
}

export default memo(Trains)
