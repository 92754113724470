import React, {useState} from 'react';

import signalboxLogo from "./signalbox-icon.png";
import classnames from 'classnames';

import './SignalboxLogo.css';

function SignalboxLogo(props) {

    const {className} = props

    return (
            <div className={classnames('SignalboxLogo', className)}>
                <img src={signalboxLogo} alt={"Signalbox Logo"}/>
            </div>
           
    );
}

export default SignalboxLogo;
