/*
 This hook contains helper methods for eta sarhing
 */
 

import React, {useEffect, useState} from "react";
import moment from 'moment';
import { useTranslation } from 'react-i18next'


function useEtaSharing() {

  const { t, i18n } = useTranslation();

    // This is for sharing the ETA. (returns false if share tray is not supported and clipboard is used)
    const onShareClick = (stop, shareUrl) => {
        let stationName = stop.name;
        let arrivalTimeStr = moment(stop.actual_arrival).format("HH:mm");
        // if share tray isn't available copy to clipboard. 
        if (!navigator.share){
          console.log('Share not supported on this browser, do it the old way.');
        
          navigator.clipboard.writeText(shareUrl)   
          return false
        }

        
        navigator.share({
          title: 'Signalbox ETA',
          text: 'On my way to ' + stationName + '. My estimated arrival time is ' + arrivalTimeStr + '. Check for live updates.',
          url: shareUrl,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
        return true
      }

    // Function for generating ETA text 
    const getEtaText = function (stop){

        const ts = stop.actual_arrival ?? stop.actual_departure;
        let actualArrival = moment(ts);
        let duration = moment.duration(actualArrival.diff(moment.now()))
        //Return ETA text
        let minutes = Math.ceil(duration.asMinutes());
        const humanizeDuration = require("humanize-duration");
        let humanizedText = humanizeDuration(duration.asMilliseconds(), { units: ["h", "m"], round: true, 
        language: i18n.language})
        if(minutes === 0){
            return (<> {t('eta_sharing.just_arrived_at')} {stop.name} </>)
        }
        if(minutes > 0){
            return <> {t('eta_sharing.arriving_at')} {stop.name} {t('eta_sharing.in')} {humanizedText} </>
            // return (<>  Arriving {duration.humanize(true)} </>)
        }
        return (<> {t('eta_sharing.arriving_at')} {stop.name} {humanizedText} {t('eta_sharing.ago')}</>) 
        }


//loop through stops to return the stop with given crs
  const getStop = function(stops, crs){
    for (let i = 0; i < (stops.length); i++) {
      //  console.log(stops[i])
        if(stops[i].crs.toLowerCase() === crs.toLowerCase()){
          // console.log(JSON.stringify(stops[i]))
          // console.log(stops[i].actualArrival)
          return stops[i]
        }
    }
    return null
  }

  const getArrivalTimeString = function(stop){

    const ts = stop.actual_arrival ?? stop.actual_departure;

    // console.log(stop.actual_arrival)
    // if(stop===null || stop.actual_arrival==null){
    //   return('------')
    // }
    return moment(ts).format("HH:mm");
  }


    return {
        onShareClick, getStop, getEtaText, getArrivalTimeString
    };
}

export default useEtaSharing;