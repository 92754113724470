//returns a list of fragments
import React from 'react';

import avantiRed from './assets/avanti-red-key.svg';
import avantiAmber from './assets/avanti-amber-key.svg';
import avantiGreen from './assets/avanti-green-key.svg';
import otherRed from './assets/other-red.svg';
import otherAmber from './assets/other-amber.svg';
import otherGreen from './assets/other-green.svg';

import './AvantiKeyIcon.css';



function AvantiKeyIcon(props){

        const style = {height: "32px", width: "32px", transform: "translate(-50%, -50%)"}

    if(props.other === true){
        if(props.color === "red"){
            return (
                <div className="AvantiKeyIcon">
                    <img src={otherRed} style={style}/>
                 </div>
            )
        }
        if(props.color === 'amber'){
            return(
                <div className="AvantiKeyIcon">
                    <img src={otherAmber} style={style}/>
                 </div>
            )
        }
    
        return(
                <div className="AvantiKeyIcon">
                    <img src={otherGreen} style={style}/>
                 </div>
            )




    }

    if(props.color === "red"){
        return (
            <div className="AvantiKeyIcon">
                <img src={avantiRed} style={style}/>
             </div>
        )
    }

    if(props.color === 'amber'){
        return(
            <div className="AvantiKeyIcon">
                <img src={avantiAmber} style={style}/>
             </div>
        )
    }

    return(
            <div className="AvantiKeyIcon">
                <img src={avantiGreen} style={style}/>
             </div>
        )
}

export default AvantiKeyIcon
