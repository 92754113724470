import React, {useState} from 'react';
import PropTypes from 'prop-types';
import CloseButton from 'components/ModalNavigation/CloseButton';
import BackButton from 'components/BackButton';

// modal content
import SearchWidget from 'components/SearchWidget';
import InfoWidget from 'components/InfoWidget';
import FindMyTrain from 'components/FindMyTrain'
import Train from 'components/Train'
import HomeWidget from 'components/HomeWidget';

import './Modal.css';
import useModal from 'hooks/useModal';



function Modal(props) {

    const { 
        showModal, 
        setShowModal,
        setModalContent,
        modalContent,
        toc,
        setToc,
        from,
        setFrom, 
        to,
        setTo,
        area,
        setArea,       
        searchTrains,
        searchError,
        searchLoading,
        setCurrentTrain,
        CustomDot,
        tracking,
        setTracking,
        setTrackRids,
        currentTrain,
        boundingBox,
        CustomKey,
        customNoTrains,
          } = props;


    const {MODAL_CONTENT} = useModal()


    if(!showModal){
        return <></>
    }


    if(modalContent==MODAL_CONTENT.SEARCH){
        return(
            <div className='Modal'>
                {/* <CloseButton className='close-button' onClick={onCloseButton}/> */}
                <SearchWidget
                    toc={toc}
                    setToc={setToc}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                    area={area}
                    setArea={setArea}
                    searchTrains={searchTrains}
                    searchError={searchError}
                    searchLoading={searchLoading}
                    setCurrentTrain={setCurrentTrain}
                    setTracking={setTracking}
                    tracking={tracking}
                    currentTrain={currentTrain}
                    CustomDot={CustomDot}
                    />
            </div >
        )
       
    }

    if(modalContent==MODAL_CONTENT.INFO){
        return (
            <div className='Modal'>
                <InfoWidget 
                    Key={CustomKey}
                />            
            </div >
        )
    }

    if(modalContent==MODAL_CONTENT.STOPS){
        return  (
            <div className='Modal'>

            <Train
                currentTrain={currentTrain}
                loading={false}
                CustomDot={CustomDot}
                tracking={tracking}
                setTracking={setTracking}
                showEta={true} />
  
         </div >
        )
    }

    if(modalContent==MODAL_CONTENT.FIND_MY_TRAIN){
        return  (
            <div className='Modal'>
                <FindMyTrain 
                currentTrain={currentTrain} 
                setCurrentTrain={setCurrentTrain}
                setTracking={setTracking}
                tracking={tracking}
                CustomDot={CustomDot}
                customNoTrains={customNoTrains}
                />
            </div >
        )
    }

    // default is to return nothing (i.e. modal is closed)
    return <></>
      
}

Modal.propTypes = {
    currentTrain: PropTypes.string,
};

export default Modal;
