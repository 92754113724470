import React, { memo } from 'react'
import PropTypes from 'prop-types'
import TrainMarker from 'components/TrainMarkers/TrainMarker'


function TrainMarkers ({
  coloring,
  currentTrain,
  currentTrains,
  heading,
  iconSize,
  onTrainMarkerClick,
  tocCode,
  onTrainSelect,
  TrainIcon,
  ...props
}) {

  const TrainMarkers = currentTrains.map(location => {
                return (
                    <TrainMarker
                        key={location.rid}
                        id={location.rid}
                        latitude={location.lat}
                        longitude={location.lon}
                        delay={location.delay}
                        size={iconSize}
                        onTrainMarkerClick={onTrainMarkerClick}
                        isFocused={location.rid === currentTrain}
                        heading={location.heading}
                        tocCode={location.tocCode}
                        coloring={coloring}
                        trainIcon={TrainIcon!==undefined && (                        
                          <TrainIcon
                            isFocused={location.rid === currentTrain}
                            delay={location.delay}
                            size={iconSize}
                            heading={location.heading}
                            tocCode={location.tocCode}
                          />
                        )
                      }
                    />
                );
            })

  return TrainMarkers
}

TrainMarkers.propTypes = {
  coloring: PropTypes.string,
  currentTrain: PropTypes.string,
  currentTrains: PropTypes.any,
  heading: PropTypes.any,
  iconSize: PropTypes.any,
  setCurrentTrain: PropTypes.any,
  tocCode: PropTypes.any,
  onTrainSelect: PropTypes.func,
  trainIcon: PropTypes.element
}

export default memo(TrainMarkers)
