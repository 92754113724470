import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './TrainIcon.css';


function TrainIcon(props) {
    const { delay,
        isFocused,
        size,
        heading=0,
        color='#ffffff',
        className } = props;

    return (
       <svg className={classnames('TrainIcon', className)} 
           xmlns="http://www.w3.org/2000/svg"
           width={size}
           height={size}
           viewBox="0 0 40 40"
       >
           <circle
                className={color}
               cx="20"
               cy="20"
               r="16"
               fillOpacity="0.8"
               stroke="#003F56"
               strokeWidth="3"
               fill={color}
           />
           <g fill="black" transform={`translate(4.5, 4.5) rotate(${heading}, 15.5, 15.5)`}>
               <path fill="#003F56"
                     d="M 8.9101796,21.718563 15.732036,5.6152695 22.600299,21.76497 15.732036,18.284431 Z"
                     id="path845"/>
           </g>
       </svg>

    );
}

TrainIcon.propTypes = {
    delay: PropTypes.number,
    isFocused: PropTypes.bool,
    size: PropTypes.number,
    heading: PropTypes.number,
    tocCode: PropTypes.string,
    coloring: PropTypes.string
};

TrainIcon.defaultProps = {
    isFocused: false,
    size: 64,
    heading: 0,
    tocCode: undefined,
    coloring: undefined
};

export default React.memo(TrainIcon);
