// This fetches train information consisting of train information.  

import {useEffect, useState} from 'react';
import axios from 'axios'
import { useQuery } from 'react-query'


function useMapParamsOverride(setTheme){
    //Uses the host name to set the theme and locale parameters. 
    // An antipatern hack to allow triggering different themes, using subdomains.  

    useEffect(() => {

        var host = window.location.hostname
        var subdomains = host.split('.')
        switch (true) {
            case subdomains.includes('avanti'):
                setTheme('avanti')
                break;
            case subdomains.includes('lner'):
                setTheme('lner')
                break;
            case subdomains.includes('tfw'):
                setTheme('tfw')
                break;
            case subdomains.includes('trainline'):
                setTheme('trainline')
                break;
            default:
        }
      }, [window.location.hostname])

    return null

}

export default useMapParamsOverride